import { day, sortBy } from '@finalytic/utils';
import {
  Avatar,
  Box,
  Divider,
  Group,
  MantineSpacing,
  Stack,
  Text,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { ReactNode, useMemo } from 'react';

export type TimelineItem = {
  id: string;
  label: ReactNode;
  date?: string;
  icon?: ReactNode;
};

type Props = {
  spacing?: MantineSpacing;
  data: TimelineItem[];
  order?: 'asc' | 'desc';
};

export const Timeline = ({ spacing = 5, data, order = 'asc' }: Props) => {
  const ordered = useMemo(() => {
    if (data.some((x) => !!x.date)) {
      const d = sortBy(data, (x) => x.date || '');

      return order === 'asc' ? d : d.reverse();
    }

    return data;
  }, [data, order]);

  return (
    <Stack gap={spacing} px="sm">
      {ordered?.map((value, index, arr) => (
        <Item
          key={value.id}
          {...value}
          spacing={spacing}
          index={index}
          arr={arr}
        />
      ))}
    </Stack>
  );
};

interface ItemProps extends TimelineItem {
  index: number;
  arr: TimelineItem[];
  spacing: MantineSpacing;
}

const Item = ({ label, date, icon, arr, index, spacing }: ItemProps) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  const isLastItem = index + 1 === arr.length;

  return (
    <Box>
      <Group wrap="nowrap">
        {typeof icon === 'string' ? <Avatar src={icon} size="sm" /> : icon}
        <Text
          component="p"
          size="sm"
          c={colorScheme === 'dark' ? undefined : colors.neutral[6]}
          m={0}
          lineClamp={2}
        >
          {label}
        </Text>
        {date && (
          <Text
            component="p"
            size="sm"
            c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
            m={0}
            ml="auto"
            sx={{
              flexShrink: 0,
            }}
          >
            {(day(date) as any).fromNow(true)} ago
          </Text>
        )}
      </Group>
      {!isLastItem && (
        <Divider orientation="vertical" h={15} ml={12} mt={spacing} />
      )}
    </Box>
  );
};
