import { gqlV2 } from '@finalytic/data';
import {
  recurring_fee_listing_subscription_rate_type_enum,
  taxBehavior_enum,
} from '@finalytic/graphql';
import { IconDefinition } from '@finalytic/icons';
import { useFormContext } from 'react-hook-form';

type Extract<T, U extends T> = T extends U ? T : never;

export const DEFAULT_RECURRING_FEE_ICON: RecurringFeeIcon = 'PercentageIcon';

export type RecurringFeeIcon = Extract<
  IconDefinition,
  | 'BedIcon'
  | 'HomeIcon'
  | 'OfficeIcon'
  | 'PercentageIcon'
  | 'StripeIcon'
  | 'BookingComIcon'
  | 'Ironing3Icon'
  | 'GardenCartIcon'
  | 'PoolIcon'
  | 'TractorIcon'
  | 'PaintIcon'
  | 'WashMachineIcon'
  | 'GrillIcon'
>;

export type RecurringFeeFormInputs = {
  id: string | undefined;
  expression: string;
  icon: RecurringFeeIcon;
  expressionUnformatted: string;
  title: string;
  type: gqlV2.recurringFeeType_enum;
  rateType: recurring_fee_listing_subscription_rate_type_enum;
  trigger: 'reservation' | 'schedule';
  triggerValue:
    | 'checkIn'
    | 'checkOut'
    | 'bookedAt'
    | 'cancelledAt'
    | 'proRata'
    | (string & {});
  creditParty: gqlV2.party_enum;
  debitParty: gqlV2.party_enum;
  creditAccountId: string;
  debitAccountId: string;
  taxRateId: string | null;
  taxRateBehavior: taxBehavior_enum;
  formulaId: string | undefined;
  bookingChannelsFilter: RecurringFeeBookingChannelsFilter;
  enableAllListings?: {
    startAt: string;
    endAt?: string;
    rate: number;
  };
};

export type RecurringFeeBookingChannelsFilter = {
  _in?: string[];
  _nin?: string[];
};

export const useRecurringFeeForm = () =>
  useFormContext<RecurringFeeFormInputs>();
