import { Badge, Select } from '@finalytic/components';
import {
  gqlV2,
  useAccountingConnection,
  useInvalidateQueries,
  useMutation,
  useQuery,
  useTeamId,
  useTeamRole,
} from '@finalytic/data';
import { ChevronIcon, CrossIcon, Icon } from '@finalytic/icons';
import {
  AutomationIcon,
  LoadingIndicator,
  SelectItem,
  showErrorNotification,
  useAppName,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import {
  Avatar,
  AvatarProps,
  Box,
  Center,
  Group,
  Tooltip,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useIsFetching } from '@tanstack/react-query';
import {
  getListingDisabledAutomations,
  whereAutomations,
} from '@vrplatform/ui-common';
import { memo, useMemo, useState } from 'react';

type Props = {
  id: string;
  tenantId: string;
  status: Maybe<gqlV2.listing_status_enum>;
  pmsStatus: Maybe<gqlV2.listing_pms_status_enum>;
  disabledAutomations: {
    settingId: any;
    automationId: any;
  }[];
  inputDisabled?: boolean;
};

function useAutomationsQuery({
  automationIds,
  listingId,
}: { automationIds: string[]; listingId: string }) {
  const { accounting } = useAccountingConnection();

  const [teamId] = useTeamId();
  const { isPartnerAdmin, isSuperAdmin, isVrpAdmin } = useTeamRole();

  const [search, setSearch] = useState<string>('');

  const listQueryData = useQuery(
    (q, args) => {
      const where = whereAutomations({
        isPartnerAdmin: args.isPartnerAdmin,
        isSuperAdmin: args.isSuperAdmin,
        isVrpAdmin: args.isVrpAdmin,
        tenantId: args.teamId,
        accountingConnectionId: args.accountingConnectionId,
        search: args.search,
      });

      return q
        .automations({
          where,
          order_by: [
            {
              ttemplate: {
                name: 'asc',
              },
            },
          ],
        })
        .map<SelectItem>((autoamtion) => ({
          label:
            autoamtion.title ||
            autoamtion.ttemplate?.title ||
            'Missing automation title',
          value: autoamtion.id,
          icon: (
            <AIcon
              templateType={autoamtion?.ttemplate?.type}
              size={16}
              isActive={false}
            />
          ),
          templateType: autoamtion.ttemplate?.type,
          status: autoamtion.status,
        }));
    },
    {
      queryKey: ['listings', 'settings', listingId],
      variables: {
        teamId,
        accountingConnectionId: accounting?.id,
        isPartnerAdmin,
        isSuperAdmin,
        isVrpAdmin,
        search: search.trim(),
      },
    }
  );

  const value = useMemo(() => {
    return (
      listQueryData.data
        ?.filter((i) => !automationIds.includes(i.value))
        .map((x: any) => ({
          ...x,
          icon: (
            <AIcon
              templateType={x.templateType}
              isActive={x.status === 'active'}
            />
          ),
        })) || []
    );
  }, [listQueryData.data, automationIds]);

  return {
    listQueryData: { ...listQueryData, setSearch },
    value,
  };
}

export const ListingStatusSelect = memo<Props>(function Cell(props) {
  // TODO: VRP-4868 remove enabled/disabled
  if (props.status === 'inactive' || props.status === 'disabled')
    return <PortalDisabledBadge {...props} />;

  if (
    // TODO: VRP-4868 remove enabled/disabled
    (props.pmsStatus === 'disabled' || props.pmsStatus === 'inactive') &&
    ['active', 'enabled'].includes(props.status || '') === false
  )
    return <PmsDisabledBadge {...props} />;

  return <AutomationSelect {...props} />;
});

const AutomationSelect = (data: Props) => {
  const { appName } = useAppName();

  const [newValue, setNewValue] = useState<Props['disabledAutomations'] | null>(
    null
  ); // new selected automationIds
  const [error, setError] = useState<boolean>(false);
  const invalidate = useInvalidateQueries();
  const isFetching = useIsFetching(['listings']);

  const automationIds = useMemo(() => {
    if (newValue) {
      return newValue.map((x) => x.automationId);
    }
    return data.disabledAutomations?.map((i) => i.automationId) || [];
  }, [data.disabledAutomations, newValue]);

  const { listQueryData, value } = useAutomationsQuery({
    automationIds,
    listingId: data.id,
  });

  const { mutate: disableListing, loading: loadingDisableMutation } =
    useMutation(
      (q, args: { listingId: string; isDisabledInPms: boolean }) => {
        return q.updateListing({
          pk_columns: { id: args.listingId },
          _set: {
            status: isDisabledInPms ? null : 'inactive',
          },
        })?.id;
      },
      {
        invalidateQueryKeys: ['listings', data.id],
      }
    );

  const { mutate, loading } = useMutation(
    (
      q,
      args: {
        listingId: string;
        teamId: string;
        value: {
          settingId: string | undefined;
          automationId: string;
        };
      }
    ) => {
      if (args.value.settingId) {
        const setting = q.delete_setting_by_pk({ id: args.value.settingId });

        return {
          disabledAutomationIds: getListingDisabledAutomations(
            setting.leftListing!
          ).disabledAutomations,
        };
      } else {
        const setting = q.insert_setting_one({
          object: {
            listingId: args.listingId,
            key: 'exclude',
            automationId: args.value.automationId,
            target: args.listingId,
            leftType: 'finalytic.listing',
            group: 'finalytic.listing',
            rightType: 'schema.boolean',
            tenant_id: args.teamId,
          },
        });

        return {
          disabledAutomationIds: getListingDisabledAutomations(
            setting.leftListing!
          ).disabledAutomations,
        };
      }
    }
  );
  // TODO: VRP-4868 remove enabled/disabled
  const isDisabledInPms = useMemo(
    () => data.pmsStatus === 'disabled' || data.pmsStatus === 'inactive',
    [data.pmsStatus]
  );

  const handleSetValue = async (
    type: 'removed' | 'added',
    value: SelectItem
  ) => {
    if (!data.id || !data.tenantId) return;

    if (type === 'removed') {
      // Insert setting
      await mutate({
        args: {
          listingId: data.id,
          teamId: data.tenantId,
          value: {
            settingId: undefined,
            automationId: value.value,
          },
        },
      }).then((result) => {
        if (!result?.disabledAutomationIds) {
          setError(true);
          return;
        }

        setNewValue(result.disabledAutomationIds);
        invalidate([data.id as any]);
      });
    } else if (type === 'added') {
      // Remove setting
      const settingId = (newValue || data.disabledAutomations)?.find(
        (i) => i.automationId === value.value
      )?.settingId;
      if (!settingId)
        return showErrorNotification({
          color: 'yellow',
          title: 'Error',
          message: 'Could not find setting.',
        });
      await mutate({
        args: {
          listingId: data.id,
          teamId: data.tenantId,
          value: {
            settingId: settingId,
            automationId: value.value,
          },
        },
      }).then((result) => {
        if (!result?.disabledAutomationIds) {
          setError(true);
          return;
        }
        setNewValue(result.disabledAutomationIds);
        invalidate([data.id as any]);
      });
    }
  };

  if (error)
    return (
      <Badge
        color="red"
        onClick={(event) => {
          event.stopPropagation();
          invalidate(['listings']);
          setError(false);
        }}
        component="button"
        rightIcon={
          isFetching ? (
            <LoadingIndicator size={'0.75rem'} color="red" />
          ) : (
            <Icon
              icon="RefreshCwIcon"
              size={12}
              color={({ colors }) => colors.red[9]}
            />
          )
        }
      >
        Failed to update
      </Badge>
    );

  return (
    <Select
      type="multiple"
      value={value}
      setValue={(_, { type, value }) => {
        return handleSetValue(type, value);
      }}
      data={{
        options: listQueryData.data || [],
        loading: listQueryData.isLoading,
        error: listQueryData.error,
        onSearchChange: listQueryData.setSearch,
      }}
      inputProps={{
        loadingMutation: loading || loadingDisableMutation,
        loadingQuery: listQueryData.isLoading,
        disabled: error || !!isFetching || loading || data.inputDisabled,
      }}
      dropdownProps={{
        position: 'bottom-end',
        preventCloseOnSelect: true,
        withinPortal: true,
        width: 370,
      }}
      customBottomActions={[
        {
          id: 'disable-listing',
          label: isDisabledInPms
            ? 'Clear status overwrite'
            : `Disable on ${appName}`,
          description: isDisabledInPms
            ? `Listing disabled in PMS, but enabled on ${appName}`
            : `Disable listing for all automations on ${appName} `,
          icon: <CrossIcon size={16} />,
          onSubmit: () =>
            disableListing({
              args: { listingId: data.id, isDisabledInPms },
            }),
        },
      ]}
    >
      {({ opened, loadingMutation, value, loadingQuery }) => {
        return (
          <InputAutomationsSelectTarget
            value={value as SelectItem[]}
            opened={opened}
            loadingMutation={!!loadingMutation}
            loadingQuery={!!loadingQuery}
            inputDisabled={!!data.inputDisabled}
          />
        );
      }}
    </Select>
  );
};

const InputAutomationsSelectTarget = function Target({
  opened,
  loadingMutation,
  loadingQuery,
  value,
  inputDisabled,
}: {
  opened: boolean;
  loadingMutation: boolean;
  loadingQuery: boolean;
  value: SelectItem[];
  inputDisabled: boolean;
}) {
  const { colors } = useMantineTheme();

  const sliced = useMemo(() => {
    if (value.length <= 15) return value.slice(0, 15);
    return value.slice(0, 15);
  }, [value]);

  if (!sliced.length && !loadingQuery) {
    return (
      <Badge
        color="yellow"
        sx={{
          cursor: inputDisabled ? 'default' : undefined,
        }}
        rightIcon={
          loadingMutation ? (
            <LoadingIndicator size={'0.75rem'} color="yellow" />
          ) : (
            !inputDisabled && (
              <Center>
                <ChevronIcon
                  color={colors.yellow[7]}
                  size={14}
                  strokeWidth={1.3}
                />
              </Center>
            )
          )
        }
      >
        Disabled for all automations
      </Badge>
    );
  }

  return (
    <>
      {loadingQuery && <LoadingIndicator size={'0.875rem'} ml="xs" mr="lg" />}
      {!!sliced.length && (
        <Tooltip.Group openDelay={100} closeDelay={100}>
          <Avatar.Group
            spacing="xs"
            px="sm"
            sx={(theme) => ({
              borderRadius: theme.radius.sm,
              display: 'inline-flex',
              justifyContent: 'flex-end',
              cursor: 'pointer',
              alignItems: 'center',
              border: '1px solid',
              borderColor: opened
                ? theme.colors[theme.primaryColor][6]
                : 'transparent',
              boxShadow: opened
                ? `0px 0px 0px 2px ${theme.colors[theme.primaryColor][4]}40`
                : undefined,
            })}
          >
            {!!sliced.length &&
              sliced.map((automation) => {
                return (
                  <Tooltip
                    label={automation.label}
                    withinPortal
                    key={automation.value}
                  >
                    <Box>{automation.icon}</Box>
                  </Tooltip>
                );
              })}
            {(value?.length || 0) > 15 && (
              <Avatar radius="xl" size="sm">
                +{value.length - 15}
              </Avatar>
            )}
          </Avatar.Group>
        </Tooltip.Group>
      )}

      {inputDisabled ? null : loadingMutation ? (
        <LoadingIndicator size={'0.875rem'} ml="xs" />
      ) : (
        <ChevronIcon size={18} ml="xs" />
      )}
    </>
  );
};

const AIcon = ({
  templateType,
  size = 'sm',
  isActive,
}: {
  templateType: Maybe<string>;
  size?: AvatarProps['size'];
  isActive: boolean;
}) => {
  const color = isActive ? 'green' : 'gray';
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Avatar
      size={size}
      color={color}
      radius="xl"
      sx={(theme) => ({
        border: `1px solid ${
          theme.colors[color][colorScheme === 'dark' ? 8 : 2]
        }`,
      })}
    >
      <AutomationIcon
        size={typeof size === 'number' ? size - 3 : 15}
        templateType={templateType}
        color={isActive ? colors[color][8] : colors[color][6]}
        sx={{ flexShrink: 0 }}
      />
    </Avatar>
  );
};

const PmsDisabledBadge = (data: Props) => {
  const { appName } = useAppName();
  const { colors } = useMantineTheme();

  const { mutate, loading } = useMutation(
    (q, args: { listingId: string }) => {
      return q.updateListing({
        pk_columns: { id: args.listingId },
        _set: {
          status: 'active',
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['listings'],
    }
  );
  return (
    <Select
      type="single"
      value={null}
      setValue={() => {
        if (!data.id || !data.tenantId) return;

        // only one option, so always same mutation
        mutate({
          args: {
            listingId: data.id,
          },
        });
      }}
      data={{
        options: [
          {
            label: 'Enable and overwrite PMS Status',
            description: `Overwrite and enable this listing on ${appName}`,
            value: 'overwrite',
          },
        ],
      }}
      inputProps={{
        loadingMutation: loading,
        disabled: data.inputDisabled,
      }}
      dropdownProps={{
        position: 'bottom-end',
        preventCloseOnSelect: true,
        withinPortal: true,
        // height: 47,
        width: 320,
      }}
    >
      {({ loadingMutation }) => {
        return (
          <Group wrap="nowrap">
            <Badge
              color="orange"
              sx={
                data.inputDisabled
                  ? {
                      cursor: 'default',
                    }
                  : undefined
              }
              leftIcon={
                <Icon
                  icon="AlertTriangleIcon"
                  size={12}
                  color={(theme) => theme.colors.orange[8]}
                />
              }
              rightIcon={
                data.inputDisabled ? null : loadingMutation ? (
                  <LoadingIndicator size={'0.75rem'} color={colors.orange[8]} />
                ) : (
                  <Icon
                    icon={'ChevronIcon'}
                    size={12}
                    color={(theme) => theme.colors.orange[8]}
                  />
                )
              }
            >
              Disabled in PMS
            </Badge>
          </Group>
        );
      }}
    </Select>
  );
};

const PortalDisabledBadge = (data: Props) => {
  const { appName } = useAppName();
  const { colors } = useMantineTheme();

  const { mutate, loading } = useMutation(
    (q, args: { listingId: string }) => {
      return q.updateListing({
        pk_columns: { id: args.listingId },
        _set: {
          status: null,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['listings'],
    }
  );

  // TODO: VRP-4868 remove enabled/disabled
  const isDisabledInPms =
    data.pmsStatus === 'disabled' || data.pmsStatus === 'inactive';

  return (
    <Group wrap="nowrap" gap={'xs'}>
      {isDisabledInPms && (
        <Tooltip label="Disabled in PMS" withinPortal withArrow>
          <Center>
            <Icon icon="AlertTriangleIcon" size={12} color={colors.orange[7]} />
          </Center>
        </Tooltip>
      )}
      <Select
        type="single"
        value={null}
        setValue={() => {
          if (!data.id || !data.tenantId) return;

          // only one option, so always same mutation
          mutate({
            args: {
              listingId: data.id,
            },
          });
        }}
        data={{
          options: isDisabledInPms
            ? [
                {
                  label: `Reset ${appName} overwrite`,
                  description: 'Reset overwrite and sync with PMS.',
                  value: 'overwrite',
                },
              ]
            : [
                {
                  label: `Enable listing on ${appName}`,
                  description: 'Reset status and enable listing.',
                  value: 'overwrite',
                },
              ],
        }}
        inputProps={{
          loadingMutation: loading,
          disabled: data.inputDisabled,
        }}
        dropdownProps={{
          position: 'bottom-end',
          preventCloseOnSelect: true,
          withinPortal: true,
          // height: 47,
        }}
      >
        {({ loadingMutation }) => {
          return (
            <Box>
              <Badge
                color="yellow"
                sx={
                  data.inputDisabled
                    ? {
                        cursor: 'default',
                      }
                    : undefined
                }
                leftIcon={
                  <Icon
                    icon="CrossCircleIcon"
                    size={12}
                    color={(theme) => theme.colors.yellow[8]}
                  />
                }
                rightIcon={
                  data.inputDisabled ? null : loadingMutation ? (
                    <LoadingIndicator
                      size={'0.75rem'}
                      color={colors.yellow[8]}
                    />
                  ) : (
                    <Icon
                      icon={'ChevronIcon'}
                      size={12}
                      color={(theme) => theme.colors.yellow[8]}
                    />
                  )
                }
              >
                Disabled on {appName}
              </Badge>
            </Box>
          );
        }}
      </Select>
    </Group>
  );
};
