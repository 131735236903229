import { Button, IconButton } from '@finalytic/components';
import {
  useDashboard,
  useEnabledFeatures,
  useSubscription,
  useTeamId,
} from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import { LoadingIndicator } from '@finalytic/ui';
import { day } from '@finalytic/utils';
import {
  ActionIcon,
  Avatar,
  Box,
  Center,
  Group,
  Indicator,
  LoadingOverlay,
  Button as MantineButton,
  Popover,
  ScrollArea,
  Stack,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { whereSyncs } from '@vrplatform/ui-common';
import { useNavigate } from 'react-router';
import { getSync } from '../../drawers/automation-history-drawer/useAutomationHistoryDrawerSubscription';

function useSyncAggregateSubscription() {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();

  return useSubscription(
    (q, args) => {
      if (!args.GL || args.dashboard !== 'propertyManager' || !args.teamId)
        return null;

      return (
        q
          .syncAggregate({
            where: whereSyncs({
              tenantId: args.teamId,
              status: ['started', 'queued'],
            }),
            order_by: [
              {
                updatedAt: 'desc_nulls_last',
              },
            ],
          })
          .aggregate?.count() || 0
      );
    },
    {
      teamId,
      dashboard,
      GL,
    }
  );
}

function useSyncsSubscription(opened: boolean) {
  const [teamId] = useTeamId();
  const [dashboard] = useDashboard();
  const { GL } = useEnabledFeatures();

  return useSubscription(
    (q, args) => {
      if (
        !args.GL ||
        args.dashboard !== 'propertyManager' ||
        !args.teamId ||
        !args.opened
      )
        return null;

      const overwrites = q
        .issueMessageOverwrites({
          order_by: [{ pattern: 'asc_nulls_last' }],
        })
        .map((o) => ({
          pattern: o.pattern || '',
          message: o.message || '',
        }));

      return q
        .syncs({
          where: whereSyncs({
            tenantId: args.teamId,
            status: ['started', 'queued'],
          }),
          order_by: [
            {
              updatedAt: 'desc_nulls_last',
            },
          ],
        })
        .map((sync) => getSync(sync, { overwrites, includeDetails: false }));
    },
    {
      teamId,
      dashboard,
      GL,
      opened,
    },
    {
      skip: !opened,
      keepPreviousData: true,
    }
  );
}

export const NavbarSyncsIconButton = () => {
  const [opened, handlers] = useDisclosure(false);

  const theme = useMantineTheme();

  const { data: count } = useSyncAggregateSubscription();

  const goto = useNavigate();
  const gotoSyncs = () => goto('/connections/syncs');

  if (!count) return null;

  return (
    <>
      <Popover
        position="bottom-start"
        opened={opened}
        onClose={handlers.close}
        shadow="lg"
        withinPortal
        radius={'md'}
        width={380}
      >
        <Popover.Target>
          <Indicator
            label={count}
            color={theme.colors.red[9]}
            position="top-end"
            styles={() => ({
              indicator: {
                height: 15,
                width: 18,
                fontSize: 11,
                borderRadius: '100%',
              },
            })}
            offset={3}
            disabled={true} // TODO:
          >
            <ActionIcon variant="transparent" onMouseEnter={handlers.open}>
              <LoadingIndicator size={'0.8rem'} color="white" />
            </ActionIcon>
          </Indicator>
        </Popover.Target>
        <Popover.Dropdown
          p={0}
          sx={{
            // overflow: 'hidden',
            height: 500,
            maxHeight: '80dvh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={(theme) => ({
              borderBottom: `1px solid ${theme.colors.gray[2]}`,
              minHeight: 40,
              display: 'flex',
              alignItems: 'center',
              paddingLeft: theme.spacing.sm,
              paddingRight: theme.spacing.xs,
              justifyContent: 'space-between',
            })}
          >
            <Text fw={500}>Active syncs</Text>
            <IconButton
              icon="CrossIcon"
              size={18}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handlers.close();
              }}
            />
          </Box>

          <Content closePopover={handlers.close} opened={opened} />

          <MantineButton
            variant="white"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              gotoSyncs();
              handlers.close();
            }}
            sx={(theme) => ({
              display: 'block',
              minHeight: 40,
              height: 'auto',
              border: 'none',
              borderTop: `1px solid ${theme.colors.gray[2]}`,
              paddingTop: rem(10),
              paddingBottom: rem(10),
              marginTop: 'auto',
              width: '100%',
              textAlign: 'center',
              color: theme.colors[theme.primaryColor][6],
              fontWeight: 'normal',
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
            })}
          >
            Go to Syncs
          </MantineButton>
        </Popover.Dropdown>
      </Popover>
    </>
  );
};

const Content = ({
  closePopover,
  opened,
}: { opened: boolean; closePopover: () => void }) => {
  const {
    error,
    refetch,
    data: syncs = [],
    isInitialLoading: loading,
  } = useSyncsSubscription(opened);

  if (loading && !syncs) {
    return (
      <Center flex={1} h="100%">
        <LoadingIndicator size="sm" />
      </Center>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Center
        flex={1}
        h="100%"
        sx={{
          flexDirection: 'column',
        }}
      >
        <Icon
          icon="AlertTriangleIcon"
          size={28}
          color={(theme) => theme.colors.red[6]}
        />
        <Text ta="center" my="md" c="red">
          Failed to load syncs
        </Text>
        <Button
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            refetch();
          }}
          leftIcon={'RefreshCwIcon'}
          variant="light"
          size="xs"
        >
          Try again
        </Button>
      </Center>
    );
  }

  if (!syncs) {
    return (
      <Center
        flex={1}
        h="100%"
        mih={250}
        maw={250}
        mx="auto"
        sx={{
          flexDirection: 'column',
        }}
      >
        <Icon
          icon="NotificationIcon"
          size={28}
          color={(theme) => theme.colors.neutral[6]}
        />
        <Text ta="center" mt="md" mb="xs" c="neutral" fw={500}>
          You're all caught up
        </Text>
        <Text ta="center" mb="md" c="gray">
          You'll be notified here for issues needed your attention.
        </Text>

        <Button
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
            refetch();
          }}
          leftIcon={'RefreshCwIcon'}
          variant="light"
          size="xs"
        >
          Refresh
        </Button>
      </Center>
    );
  }

  return (
    <ScrollArea type="always" scrollbars="y" pos="relative">
      <List syncs={syncs} closePopover={closePopover} />

      <LoadingOverlay visible={loading} />
    </ScrollArea>
  );
};

type SyncRow = NonNullable<ReturnType<typeof useSyncsSubscription>['data']>[0];

const List = ({
  syncs,
  closePopover,
}: {
  syncs: SyncRow[];
  closePopover: () => void;
}) => {
  if (!syncs.length)
    return (
      <Center
        flex={1}
        h="100%"
        mih={250}
        maw={250}
        mx="auto"
        sx={{
          flexDirection: 'column',
        }}
      >
        <Icon
          icon="NotificationIcon"
          size={28}
          color={(theme) => theme.colors.neutral[6]}
        />
        <Text ta="center" mt="md" mb="xs" c="neutral" fw={500}>
          You're all caught up
        </Text>
        <Text ta="center" mb="md" c="gray">
          You'll be notified here for issues needed your attention.
        </Text>
      </Center>
    );

  return (
    <>
      <Stack gap={0}>
        {syncs.map((sync) => (
          <SyncItem {...sync} key={sync.id} closePopover={closePopover} />
        ))}
      </Stack>
    </>
  );
};

const SyncItem = ({
  closePopover,
  ...sync
}: SyncRow & { closePopover: () => void }) => {
  const { setWorkflowIds } = useRunDrawer();

  return (
    <Group
      wrap="nowrap"
      gap="xs"
      p="sm"
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        closePopover();
        setWorkflowIds(sync.workflowId, sync.id);
      }}
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
        cursor: 'pointer',
        ':hover': {
          backgroundColor: theme.colors.neutral[0],
        },
      })}
    >
      <Avatar
        src={sync.extractConnection.icon}
        size="sm"
        styles={{
          placeholder: { visibility: 'hidden' },
        }}
        sx={(theme) => ({
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      />
      <Box flex={1}>
        <Group justify="space-between" w="100%" wrap="nowrap">
          <Text size="xs" c="gray" m={0}>
            {sync.extractConnection.name}
          </Text>

          <Text size="xs" c="gray" m={0} ta="right">
            {day(sync.createdAt).fromNow()}
          </Text>
        </Group>
        <Text>{sync.title}</Text>
      </Box>
    </Group>
  );
};

// interface CustomNotificationProps
//   extends Omit<InboxNotificationCustomProps, 'children'> {
//   to?: string;
//   icon: IconDefinition;
//   iconColor?: ExtendedCustomColors;
//   title: string;
//   description: string;
//   tenantId: string;
// }

// const CustomNotification = ({
//   icon,
//   iconColor,
//   title,
//   description,
//   to,
//   tenantId,
//   ...props
// }: CustomNotificationProps) => {
//   const { memberships } = useMe();

//   const { isInitialLoading, data: queryData } = useQuery(
//     (q, args) => {
//       const tenant = q
//         .tenant({
//           where: {
//             id: { _eq: args.tenantId },
//           },
//           limit: 1,
//         })
//         .map((tenant) => ({
//           id: tenant.id,
//           name: tenant.name || 'No name',
//         }))[0];

//       return {
//         tenant,
//       };
//     },
//     {
//       variables: {
//         tenantId: tenantId,
//       },
//     }
//   );

//   const hasMultipleMemberships =
//     memberships.filter((x) => x.status === 'active').length > 1;

//   return (
//     <InboxNotification.Custom
//       {...props}
//       style={{
//         zIndex: 1000,
//       }}
//       color="red"
//       title={
//         <Title order={4} fw={500} size="sm">
//           Owner statement ready for review
//         </Title>
//       }
//       components={
//         to
//           ? {
//               Anchor: (props) => <Link {...props} to={to} />,
//             }
//           : undefined
//       }
//       aside={
//         <Center mt={5}>
//           <Icon
//             icon={icon}
//             color={(theme) => theme.colors[iconColor || theme.primaryColor][6]}
//             size={20}
//           />
//         </Center>
//       }
//     >
//       {isInitialLoading ? (
//         <LoadingIndicator size="xs" />
//       ) : (
//         <>
//           {hasMultipleMemberships && queryData && (
//             <Text mb={rem(5)} c="gray">
//               {queryData.tenant.name}
//             </Text>
//           )}
//           <Text size="xs">{description}</Text>
//         </>
//       )}
//     </InboxNotification.Custom>
//   );
// };
