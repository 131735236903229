import { useClerk } from '@clerk/clerk-react';
import type {
  CombinedServerType,
  RouterInput,
  RouterOutput,
  TRIGGER_VARS,
} from '@finalytic/trpc-api';
import {
  showErrorNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { ensure } from '@finalytic/utils';
import { readLocalStorageValue } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import type {
  QueryObserverResult,
  UseQueryOptions,
} from '@tanstack/react-query';
import type { QueryClient } from '@tanstack/react-query';
import { httpLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { useCallback, useEffect, useId } from 'react';
import { type PropsWithChildren, useMemo } from 'react';
import { PLATFORM } from '../env';
import {
  type QueryKey,
  type QueryKeyUnion,
  useInvalidateQueries,
} from '../graphql';
import { useSpotlightContext } from '../hooks/spotlight';

export const trpc = createTRPCReact<CombinedServerType>();

export const useTrpcClient = () => {
  const auth = useClerk();

  const spotlight = useSpotlightContext();

  return useMemo(() => {
    const getHeaders = async () => {
      const accessToken = await auth.session?.getToken({
        template: 'Hasura',
      });

      const devKeys = readLocalStorageValue<Partial<TRIGGER_VARS>>({
        key: 'devKeys',
        defaultValue: {},
      });

      const headers: { [s: string]: string } = {
        authorization: `Bearer ${accessToken}`,
        'Finalytic-Platform': PLATFORM,
        platform: PLATFORM,
        'x-transaction-id': Math.random().toString(36).substring(2, 9),
      };

      if (spotlight.hypervisorQueue)
        headers['Finalytic-Hypervisor-Queue'] = spotlight.hypervisorQueue;
      if (spotlight.triggerEnv) headers['X-Trigger-Env'] = spotlight.triggerEnv;

      // dev trigger keys
      Object.entries(devKeys).forEach(([key, value]) => {
        if (value.trim())
          headers[`X-${key.replaceAll('_', '-')}`] = value.trim();
      });
      return headers;
    };

    return trpc.createClient({
      links: [
        httpLink({
          url: import.meta.env.DEV ? 'http://localhost:4001' : '/trpc',
          headers: getHeaders,
        }),
      ],
    });
  }, [auth, spotlight.hypervisorQueue, spotlight.triggerEnv]);
};

export const TrpcProvider = ({
  queryClient,
  children,
}: PropsWithChildren<{ queryClient: QueryClient }>) => {
  const trpcClient = useTrpcClient();

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      {children}
    </trpc.Provider>
  );
};

export const ExtensionTrpcProvider = ({
  queryClient,
  children,
  token,
}: PropsWithChildren<{
  queryClient: QueryClient;
  token: string;
}>) => {
  const client = useMemo(() => {
    const getHeaders = async () => {
      const headers: { [s: string]: string } = {
        authorization: `Bearer ${token}`,
        'x-transaction-id': Math.random().toString(36).substring(2, 9),
      };

      return headers;
    };

    return trpc.createClient({
      links: [
        httpLink({
          url: 'https://portal.vrplatform.app/trpc',
          headers: getHeaders,
        }),
      ],
    });
  }, [token]);

  return (
    <trpc.Provider client={client} queryClient={queryClient}>
      {children}
    </trpc.Provider>
  );
};

type Options<TTrpcRouteType extends keyof RouterInput> = {
  notificationId?: string;
  successMessage?: {
    title?: string | ((date: RouterOutput[TTrpcRouteType]) => string);
    message: string | ((date: RouterOutput[TTrpcRouteType]) => string);
  };
  errorMessage?: {
    // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
    title?: string | ((err: Error) => string | void);
    // biome-ignore lint/suspicious/noConfusingVoidType: <explanation>
    message?: string | ((err: Error) => string | void);
  };
};

export const useTrpcQuery = <TType extends keyof RouterInput>(
  type: TType,
  args: RouterInput[TType],
  options: Options<TType> & {
    skip?: boolean;
    refetchOnWindowFocus?: boolean;
    queryKey?: QueryKey;
    keepPreviousData?: boolean;
  } = {}
) => {
  const queryKeys = Array.isArray(options?.queryKey)
    ? options?.queryKey || []
    : options?.queryKey
      ? [options.queryKey]
      : [];

  const invalidate = useInvalidateQueries(queryKeys);
  const method = trpc[type] as any;

  const {
    data,
    isFetching: loading,
    refetch,
    isSuccess,
    isError,
    error,
    isInitialLoading,
  } = method.useQuery(
    args,
    ensure<UseQueryOptions>({
      enabled: !options?.skip,
      queryKey: [type, args, ...queryKeys],
      refetchOnWindowFocus: options?.refetchOnWindowFocus || false,
      keepPreviousData: options?.keepPreviousData ?? false,
    })
  );

  const handleError = useCallback(
    (error: any) => {
      const getTitle = (
        type: 'title' | 'message',
        defaultValue: string | undefined
      ) => {
        if (typeof options?.errorMessage?.[type] === 'function')
          return (options.errorMessage?.[type] as any)?.(error) || defaultValue;
        return options?.errorMessage?.[type] || defaultValue;
      };

      showErrorNotification({
        title: getTitle(
          'title',
          `Query Error: ${error.data?.httpStatus} ${error.data?.code}`
        ),
        message: getTitle('message', error.message),
      });
      invalidate();
    },
    [invalidate, options?.errorMessage]
  );

  useEffect(() => {
    if (isSuccess) {
      invalidate();
    }
  }, [isSuccess, invalidate]);

  useEffect(() => {
    if (isError) {
      handleError(error);
    }
  }, [isError, handleError, error]);

  return {
    data: data as RouterOutput[TType] | undefined,
    loading,
    refetch: refetch as () => Promise<
      QueryObserverResult<RouterOutput[TType] | undefined, Error>
    >,
    isInitialLoading: isInitialLoading as boolean,
    error: error as Error | undefined,
  };
};

export const useTrpcMutation = <TType extends keyof RouterInput>(
  type: TType,
  options: Options<TType> & {
    invalidateQueryKeys?: (QueryKeyUnion | (string & {}))[];
  } = {}
) => {
  const notificationId = useId();
  const notifyId = options?.notificationId || notificationId;

  const showNotify =
    !!options?.successMessage?.message || !!options?.notificationId;

  const invalidate = useInvalidateQueries(options.invalidateQueryKeys);
  const method = trpc[type] as any;
  const {
    mutateAsync,
    isLoading: loading,
    error,
  } = method.useMutation({
    onSuccess: (data: RouterOutput[TType]) => {
      if (options?.successMessage?.message) {
        const message =
          typeof options.successMessage?.message === 'function'
            ? options.successMessage.message(data)
            : options.successMessage?.message;
        const title =
          typeof options.successMessage?.title === 'function'
            ? options.successMessage.title(data)
            : options.successMessage?.title;

        updateSuccessNotification({
          id: notifyId,
          title: title || 'Success!',
          message: message || 'Successfully updated your action.',
        });
      }
    },
    onError: (error: any) => {
      const getTitle = (
        type: 'title' | 'message',
        defaultValue: string | undefined
      ) => {
        if (typeof options?.errorMessage?.[type] === 'function')
          return (options.errorMessage?.[type] as any)?.(error) || defaultValue;
        return options?.errorMessage?.[type] || defaultValue;
      };

      let title = getTitle(
        'title',
        `Mutation Error: ${error.data?.httpStatus || 500} ${error.data?.code || 'Unknown'}`
      );
      let message = getTitle('message', error.message);

      const isRouteMissing = message.includes(
        `Unexpected token '<', "<!DOCTYPE "... is not valid JSON`
      );

      if (isRouteMissing) {
        // we need to refresh service worker
        title = 'Update Available';
        message = 'We are refreshing the page to apply the latest updates.';

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }

      if (showNotify) {
        updateErrorNotification({
          id: notifyId,
          title,
          message,
        });
      } else {
        showErrorNotification({
          title,
          message,
        });
      }
    },
  });
  const mutate = async (
    args: RouterInput[TType]
  ): Promise<RouterOutput[TType]> => {
    if (showNotify) {
      showNotification({
        id: notifyId,
        loading: true,
        title: 'Loading...',
        message: 'We will update you shortly.',
        autoClose: false,
        radius: 10,
      });
    }

    const result = await mutateAsync(args);
    invalidate();
    return result;
  };

  return {
    mutate,
    loading,
    error,
  };
};
