import { Filter } from '@finalytic/components';
import { useInfiniteQuery } from '@finalytic/data';
import { useRunDrawer } from '@finalytic/data-ui';
import { change_bool_exp, sourceOp_bool_exp } from '@finalytic/graphql';
import { InfiniteTable, MRT_SortingState } from '@finalytic/table';
import { toTitleCase } from '@finalytic/utils';
import { getActionMessage } from '@vrplatform/ui-common';
import { useState } from 'react';
import { useSyncedFromToTableColumns } from '../_hooks';
import { WorkflowAction } from '../_types';
import { getWorkflowChangeRow } from '../_utils';

export const SyncedFromTable = ({
  workflowId,
  isWorkflowPending,
}: { workflowId: string; isWorkflowPending: boolean }) => {
  const { syncIds } = useRunDrawer();
  const isSync = !!syncIds?.includes(workflowId);

  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [search, setSearch] = useState('');

  const columns = useSyncedFromToTableColumns();

  const infiniteData = useInfiniteQuery(
    (q, { workflowId, search, sorting }, { limit, offset }) => {
      if (!workflowId)
        return {
          aggregate: 0,
          list: [],
        };

      const overwrites = q
        .issueMessageOverwrites({
          order_by: [{ pattern: 'asc_nulls_last' }],
        })
        .map((o) => ({
          pattern: o.pattern || '',
          message: o.message || '',
        }));

      if (isSync) {
        const whereChange: change_bool_exp = {
          syncId: { _eq: workflowId },
          syncType: { _eq: 'pull' },
          message: search ? { _ilike: `%${search}%` } : undefined,
        };

        const aggregate =
          q.changeAggregate({ where: whereChange }).aggregate?.count() || 0;

        const list = q
          .changes({
            where: whereChange,
            limit,
            offset,
            order_by: [{ createdAt: 'desc' }],
          })
          .map<WorkflowAction>((change) =>
            getWorkflowChangeRow(change, overwrites, 'synced-from')
          );

        return {
          aggregate,
          list,
        };
      }

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const where: sourceOp_bool_exp = {
        job: {
          plan: {
            workflowId: { _eq: workflowId },
          },
        },
        _or: search
          ? [
              {
                source: {
                  _or: [
                    {
                      remoteId: { _ilike: `%${search}%` },
                    },
                    {
                      description: { _ilike: `%${search}%` },
                    },
                  ],
                },
              },
            ]
          : undefined,
      };

      const aggregate = q.aggregateSourceOps({ where }).aggregate?.count() || 0;

      const list = q
        .sourceOps({
          where,
          limit,
          offset,
          order_by,
        })
        .map<WorkflowAction>((sourceOp) => {
          const uniqueRef = sourceOp.source.remoteId;

          return {
            id: sourceOp.id,
            automationId: sourceOp.job?.automationId,
            createdAt: sourceOp.createdAt,
            date: sourceOp.createdAt,
            hasExternalLinks: false,
            inputJson: undefined,
            outputJson: undefined,
            jobPlanId: sourceOp.job?.planId,
            jobId: sourceOp.job?.id,
            message: getActionMessage(
              sourceOp.source?.description || '',
              overwrites
            ),
            title: uniqueRef,
            uniqueRef,
            status: sourceOp.kind,
            type: toTitleCase(sourceOp.source.type),
            links: {
              paymentIds: [
                {
                  id: sourceOp.source.payment?.id,
                  title: sourceOp.source.payment?.uniqueRef,
                },
              ].filter((x) => !!x.id),
              reservationIds: [
                {
                  id: sourceOp.source.reservation?.id,
                  title: sourceOp.source.reservation?.guestName,
                },
              ].filter((x) => !!x.id),
            },
          };
        });

      return {
        list,
        aggregate,
      };
    },
    {
      variables: {
        search: search.trim(),
        workflowId,
        sorting,
        isSync,
      },
      queryKey: 'actions',
      subscribe: isWorkflowPending,
    }
  );

  const resetFilter = () => {
    setSearch('');
  };

  return (
    <InfiniteTable
      columns={columns}
      table={{
        // columns,
        hideHeader: true,
        emptyRowsFallback: 'Nothing was synced here.',
      }}
      queryData={infiniteData}
      sorting={{
        setSorting,
        sorting,
      }}
      resetFilter={resetFilter}
    >
      <Filter.Search value={search} setValue={setSearch} />
    </InfiniteTable>
  );
};
