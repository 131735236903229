import { Button, Filter } from '@finalytic/components';
import { useInfiniteQuery, useQuery, useTeamId } from '@finalytic/data';
import { InputsByJSONSchema, useRunDrawer } from '@finalytic/data-ui';
import { RocketIcon } from '@finalytic/icons';
import {
  InfiniteTable,
  type MRT_ColumnDef,
  type MRT_SortingState,
} from '@finalytic/table';
import {
  EllipsisMenuItem,
  StringParam,
  showErrorNotification,
  useQueryParam,
} from '@finalytic/ui';
import { day, toTitleCase } from '@finalytic/utils';
import {
  Box,
  Center,
  Group,
  Modal,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import { whereSources } from '@vrplatform/ui-common';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useGenericTableStore } from '../../stores';
import { ListingBaseTable } from '../../views/listings/list/ListingBaseTable';
import { useWhereListings } from '../../views/listings/list/useWhereListings';
import { PaymentsBaseTable } from '../../views/payments/PaymentsBaseTable';
import { useWherePayments } from '../../views/payments/useWherePayments';
import { ReservationsBaseTable } from '../../views/reservations/ReservationsBaseTable';
import { useWhereReservations } from '../../views/reservations/useWhereReservations';

export const RunModal = () => {
  const [queryParamDate] = useQueryParam('date', StringParam);
  const { colorScheme } = useMantineColorScheme();

  const {
    activeDrawer,
    close,
    automationId,
    runTaskLoading,
    getAutomationInput,
    postAutomations,
  } = useRunDrawer();

  const showDrawer = !!activeDrawer;

  const resetSourceSearch = useGenericTableStore((s) => s.resetFilter);
  const getFilter = useGenericTableStore((s) => s.getFilter);
  const getSelectionState = useGenericTableStore((st) => st.getSelectionState);

  const resetDrawerSettings = () => {
    resetSourceSearch();
    methods.reset();
  };

  useEffect(() => {
    if (!showDrawer) {
      resetDrawerSettings();
    }
  }, [showDrawer]);

  const methods = useForm();

  const { data } = useQuery(
    (q, args) => {
      if (!args.automationId) return null;

      const automation = q.automation({ id: args.automationId });

      const automationTemplate = automation?.ttemplate;

      const template = {
        title: automation?.title || automationTemplate?.title,
        uniqueRef: automationTemplate?.uniqueRef,
        input: automationTemplate?.input,
        params: automationTemplate?.params(),
      };

      const connections = {
        leftConnection: {
          id: automation?.leftConnection?.id,
          appId: automation?.leftConnection?.appId,
        },
        rightConnection: {
          id: automation?.rightConnection?.id,
          appId: automation?.rightConnection?.appId,
        },
      };

      return {
        template,
        connections,
      };
    },
    {
      queryKey: 'automations',
      skip: !automationId,
      variables: {
        automationId,
      },
    }
  );

  const resetStore = useGenericTableStore((t) => t.resetStore);
  const location = useLocation();

  useEffect(() => {
    resetStore();
  }, [location.search]);

  const template = data?.template;
  const connections = data?.connections;
  const isOwnerStatementAutomation = template?.uniqueRef
    ?.toLowerCase()
    .endsWith('ownerstatements');

  const input = useMemo(() => getAutomationInput(template), [template]);

  const showSources =
    template?.uniqueRef !== 'intacctTrackhs_trackhsBills' && // HARDCODED to hide source table on prime vacation
    input.namespace !== 'finalytic' &&
    input.namespace !== '';

  const whereListings = useWhereListings({
    filterByAutomationId: automationId || undefined,
  });
  const wherePayments = useWherePayments();
  const whereReservations = useWhereReservations({
    filterByAutomationId: automationId || undefined,
  });

  const connectionId = useMemo(() => {
    const app = input.namespace;
    if (connections?.leftConnection.appId === app)
      return connections.leftConnection.id;

    if (connections?.rightConnection.appId === app)
      return connections.rightConnection.id;

    return '';
  }, [input.namespace, connections]);

  const whereSources = useWhereSources(input.type, connectionId);

  const run = async (skipCachedActions: boolean) => {
    if (!automationId)
      return showErrorNotification({ message: 'Missing automation' });

    const values = methods.getValues();
    const selectedState = getSelectionState();
    const allPagesSelected = selectedState.allPagesSelected;

    let where: Record<string, any> = {};
    const filterState: Record<string, any> = getFilter();

    if (allPagesSelected) {
      if (input.schema === 'finalytic.reservation') {
        where = whereReservations;
      }
      if (input.schema === 'finalytic.payment') {
        where = wherePayments;
      }
      if (input.schema === 'finalytic.listing') {
        filterState.listing = true; // allow posting for all listings without filtering
        where = whereListings;
      }
      if (showSources) {
        // LEGACY
        // const search = (getFilter()?.search as string | undefined)?.trim();
        // filterState.source = true; // allow posting for all sources without filtering

        // if (values.date) {
        //   filterState.date = values.date;
        // }

        // where = whereSources({
        //   tenantId: teamId,
        //   connectionId,
        //   type: input.type,
        //   search: search,
        // });

        // UPDATED => same behaviour as all other automations
        where = whereSources;
      }
    }

    await postAutomations({
      skipSelectionNeccesary:
        template?.uniqueRef === 'intacctTrackhs_trackhsBills',
      automationIds: [automationId],
      allPagesSelected,
      where,
      filterState,
      extraParams: {
        ...values,
        month: values.month || day().format('YYYY-MM-01'),
      },
      getSelectedRowIds: () =>
        Object.entries(selectedState.selected)
          .filter(([_, value]) => !!value)
          .map(([key]) => key),
      selectedItemType: input.schema,
      skipCachedActions,
    }).then(() => {
      resetDrawerSettings();
      close('run');
    });
  };

  // For owner statement automations extend default value with query param
  const inputJSONSchema = useMemo(() => {
    if (isOwnerStatementAutomation) {
      const temp = { ...(template?.params || {}) };
      if (temp.properties.month) {
        temp.properties.month.default = queryParamDate;
      }

      return temp;
    }
    // Otherwise return default template params
    return template?.params;
  }, [template, queryParamDate, isOwnerStatementAutomation]);

  const children = (() => {
    if (activeDrawer === 'run') {
      const table = (() => {
        if (input.schema === 'finalytic.reservation')
          return <ReservationsTable />;
        if (input.schema === 'finalytic.payment') return <PaymentsTable />;
        if (input.schema === 'finalytic.listing') return <ListingsTable />;
        if (input.schema === 'finalytic.ownerStatement') return null;
        if (showSources)
          return <Sources type={input.type} connectionId={connectionId} />;
        return (
          <Center flex={0.7}>
            <Text fw={500} c="gray">
              Use the parameters to run this automation
            </Text>
          </Center>
        );
      })();
      const showParams =
        !table ||
        input.schema === 'finalytic.listing' ||
        input.namespace !== 'finalytic';
      return (
        <>
          {showParams && !showSources && (
            <Box
              mb="sm"
              sx={(theme) => ({
                borderBottom: `1px solid ${
                  theme.colors.gray[colorScheme === 'dark' ? 7 : 3]
                }`,
                marginInline: -16,
                paddingBlock: theme.spacing.lg,
                paddingInline: theme.spacing.lg,
                backgroundColor:
                  colorScheme === 'dark'
                    ? theme.colors.dark[7]
                    : theme.colors.gray[1],
              })}
            >
              <InputsByJSONSchema
                ignoreKeys={['listingId', 'dimensions', input.field]}
                schema={inputJSONSchema}
                methods={methods}
              />
            </Box>
          )}
          <Box
            sx={(theme) => ({
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              paddingTop: 3,
              marginTop: theme.spacing.xs,
            })}
          >
            {table}
          </Box>
        </>
      );
    }
    return null;
  })();
  return (
    <>
      <Modal
        opened={activeDrawer === 'run'}
        zIndex={101}
        onClose={close}
        size={1300}
        styles={(theme) => ({
          body: {
            paddingBottom: 0,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
            height: '85dvh',
            maxHeight: 1200,
          },
          title: {
            width: '100%',
          },
          header: {
            borderBottom: `1px solid ${
              theme.colors.gray[colorScheme === 'dark' ? 7 : 3]
            }`,
          },
        })}
        title={
          <Group justify="space-between" w="100%" pr="xl">
            <Text component="h2" size="xl" m={0} fw={500}>
              {toTitleCase(template?.title || '')}
            </Text>
            <Group>
              <Button
                size="sm"
                onClick={() => run(false)}
                variant="primary"
                leftIcon={RocketIcon}
                loading={runTaskLoading}
                menu={{
                  items: (
                    <EllipsisMenuItem
                      customIcon={<RocketIcon size={18} />}
                      loading={runTaskLoading}
                      onClick={() => run(true)}
                    >
                      Run (No Cache)
                    </EllipsisMenuItem>
                  ),
                }}
              >
                Run
              </Button>
            </Group>
          </Group>
        }
        sx={{
          marginRight: 20,
          borderRadius: 20,
          padding: 0,
        }}
      >
        {children}
      </Modal>
    </>
  );
};

const ReservationsTable = () => {
  const { automationId } = useRunDrawer();

  return (
    <ReservationsBaseTable
      onRowClickType="select"
      filterByAutomationId={automationId || undefined}
      filterType="state"
    />
  );
};

const PaymentsTable = () => {
  return <PaymentsBaseTable filterType="state" onRowClickType="select" />;
};

const ListingsTable = () => {
  const { automationId } = useRunDrawer();

  return (
    <ListingBaseTable
      onRowClickType="select"
      disableInputs
      hideRowMenu
      defaultFilterByAutomationId={automationId || undefined}
      filterType="state"
    />
  );
};

type SourceRow = NonNullable<
  ReturnType<typeof useSourceTableQuery>['data']
>['pages'][number]['list'][number];

function useSourceFilter() {
  const search = useGenericTableStore(
    (s) => (s.filter?.search as string | undefined) || ''
  );
  const date = useGenericTableStore((s) => {
    return s.filter?.date as [string | null, string | null] | undefined;
  });

  const resetFilter = useGenericTableStore((s) => s.resetFilter);
  const setFilter = useGenericTableStore((s) => s.setFilter);

  return {
    search,
    date,
    resetFilter,
    setFilter,
  };
}

const Sources = ({
  type,
  connectionId,
}: {
  type: string;
  connectionId: string;
}) => {
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'date', desc: true },
  ]);

  const { search, date, resetFilter, setFilter } = useSourceFilter();

  const rowSelection = useGenericTableStore((st) => st.selected);
  const allPagesSelected = useGenericTableStore((st) => st.allPagesSelected);
  const setRowSelection = useGenericTableStore((st) => st.setSelected);
  const setAllPagesSelected = useGenericTableStore(
    (st) => st.setAllPagesSelected
  );
  const queryData = useSourceTableQuery({
    type,
    connectionId,
    sorting,
  });

  const columns = useMemo<MRT_ColumnDef<SourceRow>[]>(
    () => [
      {
        header: 'Item ID',
        accessorKey: 'remoteId',
        maxSize: 150,
        size: 0,
        minSize: 40,
        enableSorting: true,
        Cell: ({ row }) => {
          const remoteId = row.original.remoteId;
          return (
            <Text
              sx={{
                display: 'block',
                wordBreak: 'break-all',
              }}
            >
              {remoteId}
            </Text>
          );
        },
      },
      {
        header: 'Date',
        accessorKey: 'date',
        maxSize: 200,
        size: 0,
        minSize: 50,
        enableSorting: true,
        Cell: ({ row }) => {
          const date = row.original.date;

          if (!date) return null;

          return day(date).format('MMM DD, YYYY');
        },
      },
      {
        header: 'Description',
        accessorKey: 'description',
        enableSorting: true,
      },
    ],
    []
  );

  return (
    <InfiniteTable
      resetFilter={resetFilter}
      queryData={queryData}
      columns={columns}
      table={{
        emptyRowsFallback: 'No sources found',
      }}
      selecting={{
        rowSelection: {
          rows: rowSelection,
          allPagesSelected,
        },
        setAllPagesSelected,
        setRowSelection,
      }}
      sorting={{
        sorting,
        setSorting,
      }}
    >
      <Group>
        <Filter.Search
          value={search}
          setValue={(v) => setFilter({ search: v })}
        />
        <Filter.Date
          setValue={(v) =>
            setFilter({
              date: [
                v?.[0] ? day(v?.[0]).yyyymmdd() : null,
                v?.[1] ? day(v?.[1]).yyyymmdd() : null,
              ],
            })
          }
          value={
            date
              ? ([
                  date[0] ? day(date[0]).toDate() : null,
                  date[1] ? day(date[1]).toDate() : null,
                ] as [Date | null, Date | null])
              : undefined
          }
        />
      </Group>
    </InfiniteTable>
  );
};

function useSourceTableQuery({
  connectionId,
  type,
  sorting,
}: { type: string; connectionId: string; sorting: MRT_SortingState }) {
  const [teamId] = useTeamId();

  const where = useWhereSources(type, connectionId);

  return useInfiniteQuery(
    (q, args, { limit, offset }) => {
      if (!args.type)
        return {
          list: [],
          aggregate: 0,
        };

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const where = args.where;

      const list = q
        .source({
          limit,
          offset,
          where,
          order_by,
        })
        .map((source) => ({
          id: source.id,
          description: source.description,
          date: source.date,
          remoteId: source.remoteId,
        }));

      const aggregate =
        q
          .sourceAggregate({
            where,
          })
          .aggregate?.count() || 0;

      return { list, aggregate };
    },
    {
      skip: !type,
      variables: {
        teamId,
        sorting,
        where,
        type,
      },
    }
  );
}

function useWhereSources(type: string, connectionId: string) {
  const [teamId] = useTeamId();

  const { search: s, date } = useSourceFilter();

  const search = s?.trim();

  return useMemo(
    () =>
      whereSources({
        type,
        tenantId: teamId,
        connectionId,
        search,
        date,
      }),
    [date, search, teamId, type, connectionId]
  );
}
