import {
  Collapse,
  Filter,
  Timeline,
  TimelineItem,
} from '@finalytic/components';
import { gqlV2, useMutation, useQuery, useTeam } from '@finalytic/data';
import { useAutomationModal } from '@finalytic/data-ui';
import {
  CalendarEventIcon,
  CircleDollarIcon,
  CreditCardOutcomeIcon,
  Edit3Icon,
  Icon,
  RocketIcon,
  UserIcon,
} from '@finalytic/icons';
import { LazyTable, MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuItem,
  StringParam,
  useQueryParamSet,
  useQueryParams,
} from '@finalytic/ui';
import {
  Maybe,
  formatCurrency,
  groupBy,
  hasValue,
  isUUID,
  sum,
  toTitleCase,
  utc,
} from '@finalytic/utils';
import {
  Alert,
  Anchor,
  Avatar,
  Box,
  Center,
  Group,
  LoadingOverlay,
  Stack,
  Tabs,
  Text,
  rem,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { getPaymentLineItemType } from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { SelectPostAutomationModal } from '../../modals';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { PaymentEditForm } from './PaymentEditForm';

type View = 'overview' | 'edit';

export function usePaymentDetailDrawer() {
  const [opts, set] = useQueryParams({
    payment: StringParam,
    view: StringParam,
  });

  return {
    opened: !!opts.payment,
    open: (id: string, view?: 'overview' | 'edit') =>
      set({ payment: id, view }),
    close: () => set({ payment: undefined, view: undefined }),
    paymentId: opts.payment,
    view: (opts.view || 'overview') as View,
    setView: (view: View) => set({ view }),
  };
}

function usePaymentQuery(id: Maybe<string>) {
  const [{ id: teamId, partnerId }] = useTeam();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .payments({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((payment) => {
            const actionTimeline = payment
              ?.actionLinks({
                order_by: [{ createdAt: 'desc' }],
              })
              .map<TimelineItem>((link) => {
                const action = link.action;

                const getIcon = () => {
                  const automation =
                    action?.automation || action?.jobPlan?.automation;
                  const left = {
                    id: automation?.leftConnection?.appId,
                    icon: automation?.leftConnection?.app?.iconRound,
                  };
                  const right = {
                    id: automation?.rightConnection?.appId,
                    icon: automation?.rightConnection?.app?.iconRound,
                  };

                  const apps = [left, right].filter(
                    (x) => x?.id !== 'finalytic'
                  );

                  return apps[0]?.icon || null;
                };

                const label = [
                  toTitleCase(action.schema?.uniqueRef),
                  action.title,
                ]
                  .filter(hasValue)
                  .join(' - ');

                return {
                  id: link.actionId,
                  label,
                  date: action.createdAt,
                  icon: getIcon(),
                };
              });

            const changeTimeline = payment
              .changeSourceLinks({
                order_by: [{ created_at: 'desc' }],
              })
              .map<TimelineItem>((sourceChange) => {
                const getIcon = () => {
                  const automation = sourceChange?.change.automation;

                  const left = {
                    id: automation?.leftConnection?.appId,
                    icon: automation?.leftConnection?.app?.iconRound,
                  };
                  const right = {
                    id: automation?.rightConnection?.appId,
                    icon: automation?.rightConnection?.app?.iconRound,
                  };

                  const apps = [left, right].filter(
                    (x) => x?.id !== 'finalytic'
                  );

                  return apps[0]?.icon || null;
                };

                return {
                  id: sourceChange.change.id,
                  label: [
                    toTitleCase(
                      sourceChange.change.entityUniqueRef?.split('/').at(0)
                    ),
                    sourceChange.change.message,
                  ]
                    .filter(hasValue)
                    .join(' - '),
                  date: sourceChange.created_at,
                  icon: getIcon(),
                };
              });

            const timeline = [...actionTimeline, ...changeTimeline];

            const getPaymentLine = (line: gqlV2.payment_line) => ({
              id: line.id,
              description: line.description,
              centTotal: line.centTotal,
              type: getPaymentLineItemType(line),
              lineId: line.lineId,
              reservationId: line.reservationId,
              originCentTotal: line.originCentTotal,
              originCurrency: line.originCurrency,
              reservation: {
                id: line.reservation?.id,
                confirmationCode: line.reservation?.confirmationCode,
                guestName: line.reservation?.guestName,
                checkIn: line.reservation?.checkIn,
                checkOut: line.reservation?.checkOut,
              },
            });

            const reservationLines = payment
              .lines({
                where: {
                  skipReconcile: { _eq: false },
                  reservation: {},
                },
              })
              .map((line) => getPaymentLine(line));

            return {
              id: payment?.id,
              payedAt: payment?.payedAt,
              centTotal: payment?.centTotal,
              currency: payment?.currency || 'usd',
              transferTo: payment?.metadata({ path: 'payoutDetails' }),
              userData: payment?.userdata(),
              type: payment?.type,
              metadata: payment?.metadata({ path: 'payoutDetails' }),
              connection: {
                id: payment?.connection?.id,
                name: payment?.connection?.name,
                app: {
                  id: payment?.connection?.app?.id,
                  iconRound: payment?.connection?.app?.iconRound,
                  name: payment?.connection?.app?.name,
                },
              },
              timeline,
              paymentLines: {
                reservations: reservationLines.filter(
                  (x) => x.type === 'reservationPayment'
                ),
                resolutions: reservationLines.filter(
                  (x) => x.type === 'resolution'
                ),
                adjustments: reservationLines.filter(
                  (x) => x.type === 'adjustment'
                ),
                noReservation: payment
                  .lines({
                    where: {
                      skipReconcile: { _eq: false },
                      _not: { reservation: {} },
                    },
                  })
                  .map((line) => getPaymentLine(line)),
              },
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'payments',
      variables: {
        id,
        teamId,
        partnerId,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  const data = query.data || debounced;

  return { ...query, data };
}

type Payment = NonNullable<ReturnType<typeof usePaymentQuery>['data']>;

export const PaymentDetailDrawer = () => {
  const { opened, close, paymentId, view, setView } = usePaymentDetailDrawer();

  const {
    hasAutomations,
    automations,
    handlers: automationModalHandlers,
    opened: openedAutomationModal,
    postAutomations,
  } = useAutomationModal('finalytic.payment');

  const [{ id: teamId }] = useTeam();
  const { isLoading, data: payment } = usePaymentQuery(paymentId);

  const updatePaymentData = useMutation(
    (q, { paymentId, userdata }: { paymentId: string; userdata: any }) => {
      return q.updatePayment({
        pk_columns: {
          id: paymentId,
        },
        _set: {
          userdata,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['payments', 'paymentLines'],
    }
  );

  const hasUserDataEditAccess = [
    '5599d7c0-a692-423c-9e06-3fac8d63048d',
  ].includes(teamId);

  return (
    <>
      <SelectPostAutomationModal
        opened={openedAutomationModal && !!paymentId}
        onClose={automationModalHandlers.close}
        onAutomationSubmit={(automationIds) =>
          postAutomations({
            allPagesSelected: false,
            automationIds,
            filterState: {},
            where: {},
            getSelectedRowIds: () => (paymentId ? [paymentId] : []),
            skipCachedActions: false,
            selectedItemType: 'finalytic.payment',
            skipSelectionNeccesary: false,
          }).then(close)
        }
        automations={automations}
        type="finalytic.payment"
      />
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={
            payment && (
              <Group mt={rem(5)} wrap="nowrap">
                <Avatar src={payment.connection.app.iconRound} />
                <Box>
                  <Text size="lg" fw={500}>
                    {payment?.connection?.name}
                  </Text>
                  {payment?.payedAt && (
                    <Text size="xs" color="gray" fw={400}>
                      {utc(payment?.payedAt).format('D MMM YYYY')}
                    </Text>
                  )}
                </Box>
              </Group>
            )
          }
          type="Payment"
          loading={isLoading}
          menuItems={
            view === 'overview' &&
            (hasUserDataEditAccess || hasAutomations) &&
            payment && (
              <>
                {hasAutomations && (
                  <EllipsisMenuItem
                    onClick={automationModalHandlers.open}
                    customIcon={<RocketIcon size={16} />}
                  >
                    Post Automation
                  </EllipsisMenuItem>
                )}
                {hasUserDataEditAccess && (
                  <EllipsisMenuItem
                    onClick={() => setView('edit')}
                    customIcon={<Edit3Icon size={16} />}
                  >
                    Edit User Data
                  </EllipsisMenuItem>
                )}
              </>
            )
          }
        />
        {!payment && !isLoading ? (
          <Center
            sx={(theme) => ({
              flexDirection: 'column',
              height: '80%',
              path: {
                stroke: theme.colors.gray[5],
              },
            })}
          >
            <CircleDollarIcon size={30} strokeWidth={1} />
            <Text color="gray" mt="xs">
              No payment available
            </Text>
          </Center>
        ) : view === 'edit' && payment ? (
          <PaymentEditForm
            initialValues={{
              userData: JSON.stringify(payment.userData || {}, null, 2),
            }}
            onReset={() => setView('overview')}
            isLoading={isLoading}
            handleSubmit={async (values) => {
              updatePaymentData
                .mutate({
                  args: {
                    paymentId: payment.id,
                    userdata: JSON.parse(values.userData?.trim() || '{}'),
                  },
                })
                .then(() => setView('overview'));
            }}
          />
        ) : (
          <PaymentDetail payment={payment} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const PaymentDetail = ({
  payment,
  isLoading,
}: { payment: Maybe<Payment>; isLoading: boolean }) => {
  if (!payment) return null;

  const tabs = Object.entries(payment.paymentLines || {}).reduce<
    Record<string, boolean>
  >((prev, [key, lines]) => {
    const grouped = groupBy(lines, (t) => t.reservationId || 'x');
    const noKeys = !Object.keys(grouped).length;

    if (!noKeys) prev[key] = Object.values(grouped).some((x) => x.length > 10);

    return prev;
  }, {});

  const showTabs = Object.values(tabs).some((x) => x);

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
        flex: 1,
      }}
    >
      {showTabs ? (
        <Tabs
          defaultValue="overview"
          styles={(theme) => ({
            root: {
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              flex: 1,
            },
            panel: {
              height: '100%',
              flex: 1,
              marginTop: theme.spacing.md,
              maxWidth: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: 'calc(100vh - 140px)',
            },
          })}
        >
          <Tabs.List>
            <Tabs.Tab value="overview">Overview</Tabs.Tab>
            {Object.entries(tabs).map(([key]) => {
              return (
                <Tabs.Tab key={key} value={key}>
                  {toTitleCase(key)}
                </Tabs.Tab>
              );
            })}
          </Tabs.List>
          <Tabs.Panel value="overview">
            <Stack gap={'md'} pb="md">
              <Alert icon={<Icon icon="AlertCircleIcon" size={20} />}>
                <Text size="sm">
                  This payment contains more data than usual. You can use tabs
                  to view the data more easily.
                </Text>
              </Alert>
              <OverviewTab payment={payment} />
            </Stack>
          </Tabs.Panel>

          {Object.entries(tabs).map(([key]) => {
            return (
              <Tabs.Panel key={key} value={key}>
                {key === 'noReservation' ? (
                  <WithoutReservation
                    rowData={payment.paymentLines.noReservation}
                    currency={payment.currency}
                    type="tab"
                  />
                ) : (
                  <Payments
                    currency={payment.currency}
                    rowData={
                      payment.paymentLines[key as keyof Payment['paymentLines']]
                    }
                    title={toTitleCase(key)}
                    type="tab"
                  />
                )}
              </Tabs.Panel>
            );
          })}
        </Tabs>
      ) : (
        <OverviewTab payment={payment} />
      )}

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const Payments = ({
  rowData,
  currency,
  title,
  type = 'collapse',
}: {
  rowData: Payment['paymentLines']['reservations'];
  currency: string;
  title: string;
  type?: 'tab' | 'collapse';
}) => {
  const [search, setSearch] = useState('');

  const setReservation = useQueryParamSet('reservation', StringParam);

  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  type PaymentRow = {
    id: string;
    description: string;
    reservationDates: Maybe<string>;
    centTotal: number;
    type: Maybe<string>;
    isParent: boolean;
    lines: PaymentRow[];
  };

  const groupedRowData = useMemo(
    () =>
      Object.entries(groupBy(rowData, (x) => x.reservationId)).map<PaymentRow>(
        ([reservationId, paymentLines]) => {
          const firstPaymentLine = paymentLines[0];
          const reservation = firstPaymentLine?.reservation || '';

          const guestName = !reservation?.checkIn
            ? 'No Reservation'
            : !reservation?.confirmationCode
              ? 'No Confirmation Code'
              : reservation?.guestName || 'No Guest Name';
          const code = reservation?.confirmationCode || '';
          const confirmationCode = code
            ? `#${isUUID(code) ? `${code.split('-')[0]}...` : code}`
            : '';
          const checkIn = reservation?.checkIn || '';
          const checkOut = reservation?.checkOut || '';

          const dates =
            checkIn && checkOut
              ? `${utc(checkIn).format('DD MMM')} - ${utc(checkOut).format(
                  'DD MMM YYYY'
                )}`
              : '';
          const description = [guestName, confirmationCode]
            .filter((i) => !!i)
            .join(' ');

          return {
            id: reservationId,
            isParent: true,
            centTotal: sum(paymentLines, (x) => x.centTotal || 0),
            description,
            type: firstPaymentLine.type,
            reservationDates: dates,
            lines: paymentLines.map((paymentLine) => ({
              centTotal: paymentLine.centTotal || 0,
              description: paymentLine.description || '',
              lines: [],
              type: null,
              isParent: false,
              id: paymentLine.id,
              reservationDates: undefined,
            })),
          };
        }
      ),
    [rowData]
  );
  const trimmed = search.trim();

  const filtered = useMemo(() => {
    if (!trimmed) return groupedRowData;

    return groupedRowData.filter((row) => {
      const line = row.description
        ?.toLowerCase()
        .includes(trimmed.toLowerCase());
      const childs = row.lines.some((x) =>
        x.description?.toLowerCase().includes(trimmed.toLowerCase())
      );

      return line || childs;
    });
  }, [trimmed, groupedRowData]);

  const columns = useMemo<MRT_ColumnDef<(typeof groupedRowData)[number]>[]>(
    () => [
      {
        header: 'Payed On',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const description = row.original.description;

          if (!row.parentId && row.original.reservationDates) {
            return (
              <Box>
                <Text>{description}</Text>
                <Text color="gray" size="xs">
                  {row.original.reservationDates}
                </Text>
              </Box>
            );
          }

          return description;
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 70,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return formatCurrency((row.original.centTotal || 0) / 100, currency);
        },
      },
    ],
    [currency]
  );

  if (type === 'tab') {
    return (
      <LazyTable
        data={{
          loading: false,
          error: false,
          rowCount: filtered.length,
          rows: filtered,
        }}
        table={{
          columns,
          hideSettings: true,
          hideHeader: true,
          hidePagination: true,
          onRowClick: {
            handler: (row) => {
              if (!row.original.isParent) return;
              setReservation(row.original.id, 'push');
            },
            disabled: (row) => !row.original.isParent,
          },
          emptyRowsFallback: () => (
            <Center>
              <Text size="sm" color="gray">
                No payments available
              </Text>
            </Center>
          ),
        }}
        subRows={{
          getRowCanExpand: (row) => !!row.original.lines.length,
          defaultExpanded: !!trimmed,
          getSubRows: (row) => row.lines,
        }}
        resetFilter={() => setSearch('')}
      >
        <Filter.Search setValue={setSearch} value={search} />
      </LazyTable>
    );
  }

  if (groupedRowData.length === 0) return null;

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={groupedRowData}
      columns={columns}
      subRows={{
        getRowCanExpand: (row) => !!row.original.lines.length,
        defaultExpanded: false,
        getSubRows: (row) => row.lines,
      }}
      onRowClick={{
        handler: (row) => {
          if (!row.original.isParent) return;
          setReservation(row.original.id, 'push');
        },
        disabled: (row) => !row.original.isParent,
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No payments available
          </Text>
        </Center>
      )}
    />
  );
};

const OverviewTab = ({ payment }: { payment: Payment }) => {
  const setView = useQueryParamSet('view', StringParam);

  const userDataKeys = Object.keys(payment.userData || {});

  return (
    <>
      <DrawerInfoCard
        rows={[
          {
            icon: CircleDollarIcon,
            title: 'Amount',
            text:
              typeof payment.centTotal === 'number'
                ? formatCurrency(payment.centTotal / 100, payment.currency)
                : null,
          },
          {
            icon: CalendarEventIcon,
            title: 'Paid At',
            text:
              payment?.payedAt && utc(payment.payedAt).format('MMM DD, YYYY'),
          },

          {
            icon: CreditCardOutcomeIcon,
            title: 'Transfer to',
            text: payment?.transferTo,
          },
          {
            icon: UserIcon,
            title: 'User data',
            text: !!userDataKeys.length && (
              <Anchor onClick={() => setView('edit')}>
                {userDataKeys.length} updated key
                {userDataKeys.length > 1 && 's'}
              </Anchor>
            ),
          },
        ]}
      />

      <Payments
        currency={payment.currency}
        rowData={payment.paymentLines.reservations}
        title="Reservations"
      />

      <Payments
        currency={payment.currency}
        rowData={payment.paymentLines.resolutions}
        title="Resolutions"
      />

      <Payments
        currency={payment.currency}
        rowData={payment.paymentLines.adjustments}
        title="Adjustments"
      />

      {!!payment.paymentLines.noReservation.length && (
        <WithoutReservation
          currency={payment.currency}
          rowData={payment.paymentLines.noReservation}
        />
      )}

      <ReservationTimeline data={payment.timeline} />
    </>
  );
};

const WithoutReservation = ({
  rowData,
  currency,
  type = 'collapse',
}: {
  rowData: Payment['paymentLines']['noReservation'];
  currency: string;
  type?: 'tab' | 'collapse';
}) => {
  const [search, setSearch] = useState('');

  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  const columns = useMemo<
    MRT_ColumnDef<Payment['paymentLines']['noReservation'][number]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 60,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return formatCurrency((row.original.centTotal || 0) / 100, currency);
        },
      },
    ],
    [currency]
  );

  const trimmed = search.trim();

  const filtered = useMemo(() => {
    if (!trimmed) return rowData;

    return rowData.filter((row) => {
      return row.description?.toLowerCase().includes(trimmed.toLowerCase());
    });
  }, [trimmed, rowData]);

  if (type === 'tab') {
    return (
      <LazyTable
        data={{
          loading: false,
          error: false,
          rowCount: filtered.length,
          rows: filtered,
        }}
        table={{
          columns,
          // hideTopBar: true,
          hideSettings: true,
          hideHeader: true,
          hidePagination: true,
        }}
        resetFilter={() => setSearch('')}
      >
        <Filter.Search setValue={setSearch} value={search} />
      </LazyTable>
    );
  }

  return (
    <DrawerCollapsableTable
      title={'Payment lines without reservation'}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={rowData}
      columns={columns}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No payments available
          </Text>
        </Center>
      )}
    />
  );
};

const ReservationTimeline = ({ data }: { data: Payment['timeline'] }) => {
  return (
    <Box>
      <Collapse
        title={
          <Text component="span" size="sm">
            Timeline
            <Text color="gray" ml="xs" size="xs" component="span">
              {data.length}
            </Text>
          </Text>
        }
        minHeight={30}
        defaultOpened={!!data.length}
      >
        <Timeline data={data} order="desc" />
      </Collapse>
    </Box>
  );
};
