import { InputSelect, InputWrapper } from '@finalytic/components';
import { useMutation, useQuery, useTeamId } from '@finalytic/data';
import { SelectItem } from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { rem } from '@mantine/core';
import { orderByAccount, whereAccounts } from '@vrplatform/ui-common';
import { useState } from 'react';

type Props = {
  accountId: string | null;
  contactId: string;
};

export const OwnerDefaultBankAccountInput = ({
  accountId: initial,
  contactId,
}: Props) => {
  const [teamId] = useTeamId();
  const [updatedAccountId, setUpdatedAccountId] = useState<Maybe<string>>();

  const accountId = updatedAccountId !== undefined ? updatedAccountId : initial;

  const {
    mutate,
    loading: loadingMutation,
    error: errorMutation,
  } = useMutation(
    (
      q,
      args: {
        contactId: string;
        accountId: string | null;
      }
    ) => {
      return (
        q.updateContact({
          pk_columns: {
            id: args.contactId,
          },
          _set: {
            defaultAccountId: args.accountId,
          },
        })?.defaultAccountId || null
      );
    },
    {
      invalidateQueryKeys: ['owners'],
    }
  );

  const updateAccount = async (accountId: string | null) =>
    mutate({
      args: {
        contactId,
        accountId,
      },
    }).then(setUpdatedAccountId);

  const {
    data: options = [],
    isLoading: loadingQuery,
    error,
  } = useQuery(
    (q, args) => {
      return q
        .accounts({
          where: whereAccounts({
            tenantId: args.teamId,
            type: 'bank',
          }),
          order_by: orderByAccount,
        })
        .map<SelectItem>((account) => ({
          value: account.id,
          label: account.title || 'No name',
        }));
    },
    {
      queryKey: ['accounts'],
      variables: {
        teamId,
      },
    }
  );

  const value = options.find((o) => o.value === accountId) || null;

  return (
    <InputWrapper
      label={'Select a default payout account for this owner'}
      description="Select an account to allow our system to automatize payouts for this owner."
      inputWrapperOrder={['label', 'input', 'description']}
      mb="xs"
      styles={{
        description: {
          marginTop: rem(4),
        },
      }}
    >
      <InputSelect
        type="single"
        value={value}
        setValue={(v) => updateAccount(v?.value || null)}
        data={{
          options,
          loading: loadingQuery,
          error,
          sort: null,
        }}
        inputProps={{
          placeholder: 'Default payout account',
          loadingQuery,
          loadingMutation,
          withClearButton: false,
          error: errorMutation,
        }}
        dropdownProps={{
          width: 'target',
        }}
      />
    </InputWrapper>
  );
};
