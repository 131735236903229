import { IconButton } from '@finalytic/components';
import { journalEntryEntityType_enum } from '@finalytic/graphql';
import { Icon, IconDefinition } from '@finalytic/icons';
import { type MRT_ColumnDef } from '@finalytic/table';
import { ensure, sortBy, toTitleCase, utc } from '@finalytic/utils';
import { Anchor, Box, Group, Indicator, Tooltip } from '@mantine/core';
import { Text } from '@mantine/core';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  useDepositDetailDrawer,
  useExpenseDetailDrawer,
  useReservationDetailDrawer,
} from '../../drawers';
import { useOwnerDetailDrawer } from '../../drawers/owner-drawers/useOwnerDetailDrawer';
import { type GeneralLedgerDetailRow } from './useGeneralLedgerDetailTableQuery';

export const useGeneralLedgerDetailTableColumns = () => {
  return useMemo<MRT_ColumnDef<GeneralLedgerDetailRow>[]>(
    () => [
      {
        header: 'Type',
        Header: () => null,
        mantineTableHeadCellProps: { align: 'center' },
        mantineTableBodyCellProps: { align: 'center' },
        size: 30,
        minSize: 30,
        maxSize: 0,
        Cell: ({ row }) => {
          const type = row.original.entityType;

          const icon = useMemo(
            () =>
              ensure<Record<journalEntryEntityType_enum, IconDefinition>>({
                transaction: 'ClipboardTextIcon',
                journalEntry: 'ClipboardTextIcon',
                reservation: 'CalendarEventIcon',
                recurringFee: 'PercentageCircleIcon',
                statement: 'FileTextIcon',
              })[type || 'transaction'],
            [type]
          );

          if (!type) return null;

          return (
            <Indicator
              color="red"
              disabled={row.original.status !== 'inactive'}
            >
              <Tooltip label={toTitleCase(type)} withArrow>
                <Icon icon={icon} size={18} />
              </Tooltip>
            </Indicator>
          );
        },
      },
      {
        header: 'Txn',
        size: 110,
        maxSize: 0,
        minSize: 110,
        Cell: ({ row }) => {
          const date = row.original.txnAt
            ? utc(row.original.txnAt).format('ll')
            : '';
          const txnNum = row.original.txnNum;

          return (
            <Box>
              {date && <Text>{date}</Text>}
              {txnNum && (
                <Text
                  c="gray"
                  size="xs"
                  sx={{
                    wordWrap: 'break-word',
                    wordBreak: 'break-all',
                  }}
                >
                  {txnNum}
                </Text>
              )}
            </Box>
          );
        },
      },
      {
        accessorKey: 'listing.name',
        header: 'Listing Ownership',
        Cell: ({ row }) => {
          const period = row.original.period;
          const listingName = period.listing?.name;
          // const owners = period.owners || [];

          const getDates = () => {
            const hasEndAt = period.endAt;

            if (!hasEndAt)
              return [
                utc(period.startAt).format('MMM DD, YYYY'),
                'forever',
              ].join(' - ');

            const hasSameYear =
              utc(period.startAt).year() === utc(period.endAt).year();

            if (hasSameYear)
              return [
                utc(period.startAt).format('MMM DD'),
                utc(period.endAt).format('MMM DD, YYYY'),
              ].join(' - ');

            return [
              utc(period.startAt).format('MMM DD, YYYY'),
              utc(period.endAt).format('MMM DD, YYYY'),
            ].join(' - ');
          };

          const dates = getDates();
          const owners = sortBy(
            period.owners?.map((o) => o.name) || [],
            undefined,
            'asc'
          ).join(', ');

          if (!period.id) return '';

          return (
            <Box>
              <Group gap={5} wrap="nowrap" mb={5}>
                <Tooltip withArrow withinPortal label={dates}>
                  <Icon icon="CalendarDatesIcon" size={14} />
                </Tooltip>
                <Box>
                  {listingName && <Text size="sm">{listingName}</Text>}
                  {owners && (
                    <Text size="xs" c="gray">
                      {owners}
                    </Text>
                  )}
                </Box>
              </Group>
            </Box>
          );
        },
      },
      {
        header: 'Links',
        id: 'links',
        size: 70,
        minSize: 70,
        mantineTableHeadCellProps: {
          pl: 20,
        },
        maxSize: 0,
        Cell: ({ row }) => {
          const { open: openReservation } = useReservationDetailDrawer();
          const { open: openExpenseDrawer } = useExpenseDetailDrawer();
          const { open: openDepositDrawer } = useDepositDetailDrawer();
          const { open: openOwnerDrawer } = useOwnerDetailDrawer();
          const reservation = row.original.reservation;
          const transaction = row.original.transaction;
          const transferOwnerStatement = row.original.transferOwnerStatement;
          const contact = row.original.contact;

          const goto = useNavigate();

          if (
            !reservation.id &&
            !transaction.id &&
            !transferOwnerStatement.id &&
            !contact.id
          )
            return null;

          return (
            <Group gap={0} wrap="nowrap" justify="center" w="100%">
              {reservation.id && (
                <IconButton
                  icon="CalendarEventIcon"
                  size={14}
                  onClick={() =>
                    openReservation(reservation.id, 'overview', 'pushIn')
                  }
                  tooltip={
                    <>
                      Reservation
                      <br />
                      {reservation.confirmationCode ||
                        reservation.id.split('-')[0]}
                    </>
                  }
                />
              )}
              {contact.id && (
                <IconButton
                  icon={
                    contact.companyType === 'individual'
                      ? 'UserIcon'
                      : 'OfficeIcon'
                  }
                  size={14}
                  onClick={() => {
                    if (contact.type === 'owner') openOwnerDrawer(contact.id);
                  }}
                  tooltip={
                    <>
                      {contact.type === 'owner' ? 'Owner' : 'Contact'}
                      <br />
                      {contact.name}
                    </>
                  }
                />
              )}
              {transaction.id && transaction.type !== 'transfer' && (
                <IconButton
                  onClick={() =>
                    transaction.type === 'expense'
                      ? openExpenseDrawer(transaction.id, 'pushIn')
                      : openDepositDrawer(transaction.id, 'pushIn')
                  }
                  icon={
                    transaction.type === 'expense'
                      ? 'MinusCircleIcon'
                      : 'PlusCircleIcon'
                  }
                  tooltip={
                    <>
                      {toTitleCase(transaction.type)}
                      <br />
                      {transaction.uniqueRef || transaction.id.split('-')[0]}
                    </>
                  }
                  size={14}
                />
              )}
              {transferOwnerStatement.id && (
                <IconButton
                  icon="ClipboardTextIcon"
                  size={15}
                  onClick={() =>
                    goto(
                      `/statement/${transferOwnerStatement.listingOwnershipPeriodId}?date=${utc(
                        transferOwnerStatement.startAt
                      ).format(
                        'YYYY-MM-DD'
                      )}&cur=${transferOwnerStatement.currency}`
                    )
                  }
                  tooltip={utc(transferOwnerStatement.startAt).format(
                    'YYYY-MM'
                  )}
                />
              )}
            </Group>
          );
        },
      },
      {
        accessorKey: 'description',
        header: 'Description',
        Cell: ({ row }) => {
          const ownerStatement = row.original.attachedToOwnerStatement;

          return (
            <Box>
              <Text>{row.original.description}</Text>
              {row.original.transaction.uniqueRef && (
                <Text size="xs" c="gray">
                  {row.original.transaction.uniqueRef}
                </Text>
              )}
              {ownerStatement.id && (
                <Group wrap="nowrap" gap={5} align="flex-start">
                  <Icon icon="ClipboardTextIcon" size={14} mt={3} />
                  <Tooltip withArrow label="Owner Statement">
                    <Anchor
                      display={'block'}
                      component={Link}
                      to={`/statement/${ownerStatement.listingOwnershipPeriodId}?date=${utc(
                        ownerStatement.startAt
                      ).format('YYYY-MM-DD')}&cur=${ownerStatement.currency}`}
                      sx={{
                        wordBreak: 'break-all',
                      }}
                    >
                      {utc(ownerStatement.startAt).format('YYYY-MM')}
                    </Anchor>
                  </Tooltip>
                </Group>
              )}
            </Box>
          );
        },
      },

      {
        size: 60,
        minSize: 60,
        maxSize: 0,
        header: 'Party',
        Header: '',
        accessorKey: 'party',
        mantineTableBodyCellProps: {
          align: 'center',
        },
        mantineTableHeadCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => {
          const party = row.original.party;

          if (!party) return null;

          return (
            <Tooltip label={party} withArrow>
              <Icon
                icon={party === 'Owners' ? 'UsersIcon' : 'DashboardIcon'}
                size={18}
              />
            </Tooltip>
          );
        },
      },
      {
        accessorKey: 'accountTitle',
        header: 'Account',
        maxSize: 160,
        Cell: ({ row }) =>
          row.original.accountTitle
            ? `[${row.original.accountCode || 'N/A'}] ${
                row.original.accountTitle
              }`
            : '-',
      },

      {
        accessorKey: 'debit',
        maxSize: 75,
        header: 'Debit',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
      },
      {
        accessorKey: 'credit',
        header: 'Credit',
        mantineTableHeadCellProps: {
          align: 'right',
        },
        mantineTableBodyCellProps: {
          align: 'right',
        },
        maxSize: 75,
      },
    ],
    []
  );
};
