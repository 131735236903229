import {
  TrpcApiError,
  captureSentryError,
  useInvalidateQueries,
  useTransactionsServiceDeleteTransaction,
} from '@finalytic/data';
import {
  DeleteModal,
  showLoadingNotification,
  showWarnNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { Maybe } from '@finalytic/utils';
import { useDebouncedValue } from '@mantine/hooks';
import { useId } from 'react';
import { DepositRow } from './useDepositsTableQuery';

interface ModalProps {
  opened: boolean;
  closeModal: () => void;
  deposit: Maybe<DepositRow>;
}

export const DepositEllipsisMenuModals = ({
  deleteModal,
  deposit: initial,
}: {
  deposit: ModalProps['deposit'];
  deleteModal: {
    opened: boolean;
    closeModal: () => void;
  };
}) => {
  const [debounced] = useDebouncedValue(initial, 500);

  const deposit = initial || debounced;

  return (
    <>
      <Delete {...deleteModal} deposit={deposit} />
    </>
  );
};

const Delete = ({ opened, closeModal, deposit }: ModalProps) => {
  const depositId = deposit?.id;
  const notifyId = useId();

  const invalidate = useInvalidateQueries(['deposits', 'reservations']);

  const { mutateAsync: mutate } = useTransactionsServiceDeleteTransaction({
    onSuccess: () => {
      invalidate();
    },
  });

  const submit = async () => {
    if (!depositId) return showWarnNotification({ message: 'Missing deposit' });

    try {
      showLoadingNotification({ id: notifyId });
      const res = await mutate({
        id: depositId,
        onLocked: 'archive',
      });

      updateSuccessNotification({
        id: notifyId,
        title: 'Success!',
        message: `Deposit was ${res.status} successfully.`,
      });
    } catch (err: any) {
      const error = err as TrpcApiError;
      const message =
        error?.body?.message ||
        'We failed to delete the deposit. Please try again later and if the problem persists, contact support.';

      if (error.status !== 400) captureSentryError(message);

      updateErrorNotification({
        id: notifyId,
        title: 'Failed to delete deposit',
        message,
      });
    }
  };

  return (
    <DeleteModal
      opened={opened}
      onClose={closeModal}
      onSubmit={submit}
      title={'Delete this deposit?'}
      subtitle={
        'Are you sure you want to delete this deposit? This action cannot be undone.'
      }
    />
  );
};
