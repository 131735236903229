import { useMantineTheme } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  LabelProps,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
} from 'recharts';

type Data = Record<string, number | string | boolean | null>[];

type AreaChartProps<TX extends string, TY extends string> = {
  data: Data;
  xAxisKey: TX;
  yAxisKey: TY;
  xAxisProps?: Omit<XAxisProps, 'ref'>;
  tooltipProps?: TooltipProps<TX, TY>;
  xAxisLabels?: LabelProps[];
};

export const AreaChart = <TX extends string, TY extends string>({
  data,
  xAxisKey,
  yAxisKey,
  xAxisProps,
  tooltipProps,
  xAxisLabels,
}: AreaChartProps<TX, TY>) => {
  const { classes } = useStyles();

  const theme = useMantineTheme();

  const lineColor = theme.colors[theme.primaryColor][6];
  const areaColor = theme.colors[theme.primaryColor][7];

  return (
    <ResponsiveContainer width="100%" height="100%" className={classes.chart}>
      <ComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 30,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis dataKey={xAxisKey} {...(xAxisProps || {})}>
          {xAxisLabels?.map((label, index) => (
            <Label key={index} {...label} />
          ))}
        </XAxis>
        <Tooltip {...(tooltipProps || {})} />
        <Area
          type="monotone"
          dataKey={yAxisKey}
          stroke={lineColor}
          strokeWidth={2}
          fill={areaColor}
          fillOpacity={0.08}
        />
        <Line
          type="basis"
          dataKey="previous"
          stroke="#D4D4D4"
          strokeWidth={2}
          dot={false}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

const useStyles = createStyles((_theme) => ({
  chart: {
    '.recharts-cartesian-axis-line': {
      stroke: 'transparent',
    },
  },
}));
