import { useTeamId, useTrpcQuery } from '@finalytic/data';
import {
  AreaChart,
  Card,
  CardTitle,
  Legend,
  PercentageBadge,
  getPercentageDifference,
} from '@finalytic/ui';
import { day, formatCurrency, sum, utc } from '@finalytic/utils';
import {
  Anchor,
  Box,
  Group,
  Card as MantineCard,
  MantineSize,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export type PmDashboardChartBaseBaseProps = {
  startDate: string;
  midDate: string;
  endDate: string;
  currency: string;
};

interface Props extends PmDashboardChartBaseBaseProps {
  title: string;
  isCurrencyAmount?: boolean;
  total: 'sum' | 'average';
  href: string;
  borderRadius?: MantineSize;
  type: Parameters<typeof useTrpcQuery<'metrics'>>[1]['type'];
}

export const PmDashboardChartBase = ({
  title,
  isCurrencyAmount,
  total: totalType,
  href,
  borderRadius = 'lg',
  startDate,
  endDate,
  type,
  currency,
  midDate,
}: Props) => {
  const [teamId] = useTeamId();

  // primary period OR all
  const {
    data: primary,
    loading: loading1,
    error: error1,
  } = useTrpcQuery('metrics', {
    tenantId: teamId,
    startDate: totalType === 'average' ? midDate : startDate,
    endDate,
    type,
  });

  // if average => comparison period
  const {
    data: d,
    loading: loading2,
    error: error2,
  } = useTrpcQuery(
    'metrics',
    {
      tenantId: teamId,
      startDate,
      endDate: day(midDate).subtract(1, 'day').yyyymmdd(),
      type,
    },
    {
      skip: totalType === 'sum',
    }
  );

  const secondary = totalType === 'average' ? d : null;

  const loading = loading1 || loading2;
  const error = error1 || error2;

  const { data, first, last, total, previousTotal, diffPercentage } =
    useMemo(() => {
      const pdata = (primary || []).filter(
        (x) => (x.currency || 'usd').toLowerCase() === currency.toLowerCase()
      );

      const sdata = (secondary || []).filter(
        (x) => (x.currency || 'usd').toLowerCase() === currency.toLowerCase()
      );

      const primaryData =
        totalType === 'average'
          ? pdata
          : pdata.slice(pdata.length / 2, pdata.length);

      const secondaryData =
        totalType === 'average' ? sdata : pdata.slice(0, pdata.length / 2);

      const sums = sum(primaryData, 'value');
      const previousSum = sum(secondaryData, 'value');

      const total: Record<typeof totalType, number> = {
        sum: sums,
        average: sums / primaryData.length,
      };

      const previousTotal: Record<typeof totalType, number> = {
        sum: previousSum,
        average: previousSum / secondaryData.length,
      };

      return {
        data: primaryData.map((item, index) => {
          const previousItem = secondaryData[index];

          return {
            ...item,
            previous: previousItem?.value,
            currentItem: item,
            previousItem,
          };
        }),
        first: primaryData[0],
        last: primaryData[primaryData.length - 1],
        total: isCurrencyAmount
          ? formatCurrency(total[totalType], currency)
          : total[totalType],
        previousTotal: isCurrencyAmount
          ? formatCurrency(previousTotal[totalType], currency)
          : previousTotal[totalType],
        diffPercentage: getPercentageDifference(
          total[totalType],
          previousTotal[totalType]
        ),
      };
    }, [primary, isCurrencyAmount, totalType, currency, secondary]);

  return (
    <Card error={error} loading={loading} radius={borderRadius}>
      <Group justify="space-between" align="flex-start">
        <CardTitle
          title={title}
          total={total}
          previousTotal={previousTotal}
          diffPercentage={diffPercentage}
        />

        <Anchor pt={4} component={Link} to={href}>
          View all
        </Anchor>
      </Group>
      <Box sx={{ flexGrow: 1, display: 'flex', height: 270 }} w="100%">
        {data.length > 0 ? (
          <AreaChart
            data={data as any[]}
            xAxisKey="date"
            yAxisKey="value"
            xAxisProps={{
              interval: 4,
              tickSize: 0,
              ticks: [],
              tickFormatter: () => '',
            }}
            xAxisLabels={[
              {
                children: day(first.date).format('DD MMM'),
                position: 'left',
                offset: -30,
              },
              {
                children: day(last.date).format('DD MMM'),
                position: 'right',
                offset: -30,
              },
            ]}
            tooltipProps={{
              content: ({ payload }: any) => {
                const theme = useMantineTheme();
                const legendColor = theme.colors[theme.primaryColor][6];

                const prev = payload?.[0]?.payload?.previousItem;
                const current = payload?.[0]?.payload?.currentItem;

                const formatLabel = (d: string) => {
                  if (!d) return '';

                  const labelDate = utc(d);
                  return labelDate.format('MMM DD, YYYY');
                };
                const formatValue = (v: any) =>
                  isCurrencyAmount ? formatCurrency(v, currency) : v;

                const diff = getPercentageDifference(
                  current?.value,
                  prev?.value
                );
                return (
                  <MantineCard p="xs" shadow="md" withBorder miw={250}>
                    <Group justify="space-between" mb="xs">
                      <Text c="#525252">{title}</Text>
                      <PercentageBadge value={diff} precision={0} />
                    </Group>

                    <Group justify="space-between">
                      <Legend
                        legendColor={legendColor}
                        label={formatLabel(current?.date)}
                      />
                      <Text ta="right"> {formatValue(current?.value)}</Text>
                    </Group>
                    <Group justify="space-between">
                      <Legend label={formatLabel(prev?.date)} />
                      <Text ta="right">{formatValue(prev?.value)}</Text>
                    </Group>
                  </MantineCard>
                );
              },
            }}
          />
        ) : (
          <Text
            size="sm"
            component="p"
            ta="center"
            c="gray"
            sx={{ alignSelf: 'center', justifySelf: 'center', width: '100%' }}
          >
            No data available for this period
          </Text>
        )}
      </Box>
    </Card>
  );
};
