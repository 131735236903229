import {
  Badge,
  Collapse,
  Filter,
  Timeline,
  type TimelineItem,
} from '@finalytic/components';
import {
  useDashboard,
  useEnabledFeatures,
  useMutation,
  useQuery,
  useTeam,
  useTeamRole,
} from '@finalytic/data';
import { HiddenFeatureIndicator, useAutomationModal } from '@finalytic/data-ui';
import {
  CalendarDatesIcon,
  CalendarEventIcon,
  CheckCircleIcon,
  CrossCircleIcon,
  CrossIcon,
  Edit3Icon,
  HomeIcon,
  Icon,
  InfoIcon,
  LoaderIcon,
  RocketIcon,
  UserIcon,
} from '@finalytic/icons';
import { LazyTable, type MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuItem,
  StringParam,
  useQueryParamSet,
} from '@finalytic/ui';
import {
  type Maybe,
  formatCurrency,
  groupBy,
  hasValue,
  sum,
  toTitleCase,
  uniqueBy,
  utc,
} from '@finalytic/utils';
import {
  Alert,
  Anchor,
  Avatar,
  Box,
  Card,
  Center,
  Group,
  LoadingOverlay,
  Progress,
  Stack,
  Tabs,
  Text,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {
  XIMPLIFI_TENANT_ID,
  formatUserName,
  getListingName,
  queryReservationFinancials,
  queryReservationPaymentLines,
} from '@vrplatform/ui-common';
import { useMemo, useState } from 'react';
import { ReservationStatusBadge } from '../../components';
import { useFileDownload } from '../../hooks';
import { SelectPostAutomationModal } from '../../modals';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { ReservationEditForm } from './ReservationEditForm';
import { useHasReservationUserDataEditAccess } from './useHasReservationUserDataEditAccess';
import { useReservationDetailDrawer } from './useReservationDetailDrawer';

function useReservationQuery(id: Maybe<string>) {
  const [{ id: teamId, partnerId }] = useTeam();
  const [dashboard] = useDashboard();

  const query = useQuery(
    (q, args) => {
      if (!args.id) return null;

      return (
        q
          .reservations({
            where: {
              id: { _eq: args.id },
            },
          })
          .map((reservation) => {
            const financials = queryReservationFinancials(reservation, {
              partnerId: args.partnerId,
              tenantId: args.teamId,
              GL: false,
            });

            const exluded = financials.filter((x) => x.isInvoice !== 'invoice');
            const included = financials.filter(
              (x) => x.isInvoice === 'invoice'
            );

            const actionTimeline =
              args.dashboard === 'owner'
                ? []
                : reservation
                    ?.actionLinks({
                      order_by: [{ createdAt: 'desc' }],
                    })
                    .map<TimelineItem>((link) => {
                      const action = link.action;

                      const getIcon = () => {
                        const automation =
                          action?.automation || action?.jobPlan?.automation;
                        const left = {
                          id: automation?.leftConnection?.appId,
                          icon: automation?.leftConnection?.app?.iconRound,
                        };
                        const right = {
                          id: automation?.rightConnection?.appId,
                          icon: automation?.rightConnection?.app?.iconRound,
                        };

                        const apps = [left, right].filter(
                          (x) => x?.id !== 'finalytic'
                        );

                        return apps[0]?.icon || null;
                      };

                      const label = [
                        toTitleCase(action.schema?.uniqueRef),
                        action.title,
                      ]
                        .filter(hasValue)
                        .join(' - ');

                      return {
                        id: link.actionId,
                        label,
                        date: action.createdAt,
                        icon: getIcon(),
                      };
                    });
            const changeTimeline =
              args.dashboard === 'owner'
                ? []
                : reservation
                    .changeSourceLinks({
                      order_by: [{ created_at: 'desc' }],
                    })
                    .map<TimelineItem>((sourceChange) => {
                      const getIcon = () => {
                        const automation = sourceChange?.change.automation;

                        const left = {
                          id: automation?.leftConnection?.appId,
                          icon: automation?.leftConnection?.app?.iconRound,
                        };
                        const right = {
                          id: automation?.rightConnection?.appId,
                          icon: automation?.rightConnection?.app?.iconRound,
                        };

                        const apps = [left, right].filter(
                          (x) => x?.id !== 'finalytic'
                        );

                        return apps[0]?.icon || null;
                      };

                      return {
                        id: sourceChange.change.id,
                        label: [
                          toTitleCase(
                            sourceChange.change.entityUniqueRef
                              ?.split('/')
                              .at(0)
                          ),
                          sourceChange.change.message,
                        ]
                          .filter(hasValue)
                          .join(' - '),
                        date: sourceChange.created_at,
                        icon: getIcon(),
                      };
                    });
            const timeline = [...actionTimeline, ...changeTimeline];

            const paymentLines = queryReservationPaymentLines(reservation, {
              includeAllType2s: true,
            });

            const reservationlistingConnectionListing = {
              id: reservation.listingConnection?.listing?.id,
              name: getListingName(reservation.listingConnection?.listing),
            };

            const reservationListing = {
              id: reservation.listingId,
              name: getListingName(reservation.listing),
            };

            return {
              id: reservation.id,
              bookedAt: reservation.bookedAt,
              connection: {
                id: reservation.connection?.id,
                app: {
                  id: reservation.connection?.app?.id,
                  iconRound: reservation.connection?.app?.iconRound,
                  name: reservation.connection?.app?.name,
                },
              },
              channel:
                reservation.channel?.uniqueRef || reservation.bookingPlatform,
              cancelledAt: reservation.cancelledAt,
              checkIn: reservation.checkIn,
              checkOut: reservation.checkOut,
              guestName: reservation.guestName,
              status: reservation.status,
              centTotal: reservation.centTotal,
              centPaid: reservation.centPaid ?? 0,
              paidStatus: reservation.paidStatus,
              userData: reservation.userdata(),
              confirmationCode: reservation.confirmationCode,
              pmsReferenceCode: reservation.pmsReferenceCode,
              hasListingOwnership: !!reservation.listing
                ?.ownershipPeriods({
                  limit: 1,
                })
                .map((x) => x.id)[0],
              listing: reservationListing?.id
                ? reservationListing
                : reservationlistingConnectionListing,
              currency: reservation.currency || 'usd',
              financials: {
                included,
                exluded,
              },
              timeline,
              paymentLines: paymentLines.filter((x) => x.isResevationPayment),
              resolutionLines: paymentLines.filter(
                (x) => !x.isResevationPayment
              ),
              files: reservation
                ?.files({
                  order_by: [{ startDate: 'desc_nulls_last' }],
                })
                .map((file) => ({
                  id: file.id,
                  type: file.type,
                  filename: file.filename,
                  ownerName: file.owner && formatUserName(file.owner),
                  startDate: file.startDate,
                  endDate: file.endDate,
                })),
            };
          })[0] || null
      );
    },
    {
      skip: !id,
      queryKey: 'reservations',
      variables: {
        id,
        teamId,
        partnerId,
        dashboard,
      },
    }
  );

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

type Reservation = NonNullable<ReturnType<typeof useReservationQuery>['data']>;

export const LegacyReservationDetailDrawer = () => {
  const { opened, close, reservationId, view, setView } =
    useReservationDetailDrawer();

  const { colorScheme } = useMantineColorScheme();

  const { colors } = useMantineTheme();
  const { isLoading, data: reservation } = useReservationQuery(reservationId);

  const {
    hasAutomations,
    automations,
    handlers: automationModalHandlers,
    opened: openedAutomationModal,
    postAutomations,
  } = useAutomationModal('finalytic.reservation');

  const updatePaymentData = useMutation(
    (
      q,
      { reservationId, userdata }: { reservationId: string; userdata: any }
    ) => {
      return q.updateReservation({
        pk_columns: {
          id: reservationId,
        },
        _set: {
          userdata,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['reservations'],
    }
  );

  const hasUserDataEditAccess = useHasReservationUserDataEditAccess();

  return (
    <>
      <SelectPostAutomationModal
        opened={openedAutomationModal && !!reservationId}
        onClose={automationModalHandlers.close}
        onAutomationSubmit={(automationIds) =>
          postAutomations({
            allPagesSelected: false,
            automationIds,
            filterState: {},
            getSelectedRowIds: () => (reservationId ? [reservationId] : []),
            where: {},
            selectedItemType: 'finalytic.reservation',
            skipCachedActions: false,
            skipSelectionNeccesary: false,
          }).then(close)
        }
        type="finalytic.reservation"
        automations={automations}
      />
      <Drawer opened={opened} onClose={close} size={550}>
        <DrawerHeader
          closeDrawer={close}
          title={
            reservation && (
              <Group mt={rem(5)} wrap="nowrap">
                <Avatar src={reservation.connection.app.iconRound} />
                <Box>
                  <Text size="lg" fw={500}>
                    {reservation.guestName}
                  </Text>
                  <Text
                    size="xs"
                    c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
                    fw={400}
                  >
                    {`${uniqueBy(
                      [
                        reservation?.confirmationCode,
                        reservation?.pmsReferenceCode,
                      ].filter((x) => x)
                    ).join(', ')}`}
                  </Text>
                </Box>
              </Group>
            )
          }
          type="Reservation"
          loading={isLoading}
          menuItems={
            view === 'overview' &&
            (hasUserDataEditAccess || hasAutomations) &&
            reservation && (
              <>
                {hasAutomations && (
                  <EllipsisMenuItem
                    onClick={automationModalHandlers.open}
                    customIcon={<RocketIcon size={16} />}
                  >
                    Post Automation
                  </EllipsisMenuItem>
                )}
                {hasUserDataEditAccess && (
                  <EllipsisMenuItem
                    onClick={() => setView('edit')}
                    customIcon={<Edit3Icon size={16} />}
                  >
                    Edit User Data
                  </EllipsisMenuItem>
                )}
              </>
            )
          }
        />
        {!reservation && !isLoading ? (
          'No reservation found'
        ) : view === 'edit' && reservation ? (
          <ReservationEditForm
            initialValues={{
              userData: JSON.stringify(reservation.userData || {}, null, 2),
            }}
            onReset={() => setView('overview')}
            isLoading={isLoading}
            handleSubmit={async (values) => {
              updatePaymentData
                .mutate({
                  args: {
                    reservationId: reservation.id,
                    userdata: JSON.parse(values.userData?.trim() || '{}'),
                  },
                })
                .then(() => setView('overview'));
            }}
          />
        ) : (
          <ReservationDetail reservation={reservation} isLoading={isLoading} />
        )}
      </Drawer>
    </>
  );
};

const ReservationDetail = ({
  reservation,
  isLoading,
}: { reservation: Maybe<Reservation>; isLoading: boolean }) => {
  const { GL } = useEnabledFeatures();
  const tabs = useMemo(() => {
    const tabs: (
      | 'financials'
      | 'excluded'
      | 'payments'
      | 'paymentFeesAndReserves'
      | 'journalEntries'
    )[] = [];

    if (!reservation) return [];

    const limit = 10;

    if (
      !GL &&
      Object.values(
        groupBy(reservation.paymentLines, (x) => x.paymentId || '')
      ).some((x) => x.length > limit)
    )
      tabs.push('payments');

    if (
      Object.values(
        groupBy(reservation.resolutionLines, (x) => x.paymentId || '')
      ).some((x) => x.length > limit)
    )
      tabs.push('paymentFeesAndReserves');

    if (reservation.financials.included.length > limit) tabs.push('financials');
    if (reservation.financials.exluded.length > limit) tabs.push('excluded');

    return tabs;
  }, [reservation, GL]);

  if (!reservation) return null;

  // 2 financials not grouped
  // payments/resolutions grouped

  const showTabs = !!tabs.length;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
        flex: 1,
      }}
    >
      {!showTabs ? (
        <Overview reservation={reservation} />
      ) : (
        <>
          <Tabs
            defaultValue="overview"
            styles={(theme) => ({
              root: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                flex: 1,
              },
              panel: {
                height: '100%',
                flex: 1,
                marginTop: theme.spacing.md,
                maxWidth: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                maxHeight: 'calc(100vh - 140px)',
              },
            })}
          >
            <Tabs.List>
              <Tabs.Tab value="overview">Overview</Tabs.Tab>
              {tabs.map((key) => {
                return (
                  <Tabs.Tab key={key} value={key}>
                    {toTitleCase(key)}
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>

            <Tabs.Panel value="overview">
              <Stack gap={'md'} pb="md">
                <Alert icon={<Icon icon="AlertCircleIcon" size={20} />}>
                  <Text size="sm">
                    This reservation contains more data than usual. You can use
                    tabs to view the data more easily.
                  </Text>
                </Alert>
                <Overview reservation={reservation} />
              </Stack>
            </Tabs.Panel>
            {tabs.map((key) => {
              return (
                <Tabs.Panel key={key} value={key}>
                  {key === 'payments' && (
                    <Payments
                      currency={reservation.currency}
                      rowData={reservation.paymentLines}
                      title="Payments"
                      type="tab"
                    />
                  )}
                  {key === 'paymentFeesAndReserves' && (
                    <Payments
                      currency={reservation.currency}
                      rowData={reservation.resolutionLines}
                      title="Payments"
                      type="tab"
                    />
                  )}

                  {key === 'financials' && (
                    <Financials
                      currency={reservation.currency}
                      rowData={reservation.financials.included}
                      reservationId={reservation.id}
                      title="Payments"
                      type="tab"
                    />
                  )}

                  {key === 'excluded' && (
                    <Financials
                      currency={reservation.currency}
                      rowData={reservation.financials.exluded}
                      reservationId={reservation.id}
                      title="Payments"
                      type="tab"
                    />
                  )}
                </Tabs.Panel>
              );
            })}
          </Tabs>
        </>
      )}
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

const Overview = ({ reservation }: { reservation: Reservation }) => {
  const setListing = useQueryParamSet('listing', StringParam);
  const setView = useQueryParamSet('view', StringParam);

  const userDataKeys = Object.keys(reservation.userData || {});

  return (
    <>
      <DrawerInfoCard
        rows={[
          {
            icon: LoaderIcon,
            title: 'Status',
            text: <ReservationStatusBadge status={reservation.status} />,
          },

          {
            icon: HomeIcon,
            title: 'Listing',
            text: (
              <Anchor
                onClick={() => setListing(reservation.listing.id, 'push')}
              >
                {reservation.listing.name}
              </Anchor>
            ),
          },
          {
            icon: CalendarEventIcon,
            title: 'Booked',
            text: `${
              reservation.bookedAt
                ? utc(reservation.bookedAt).format('MMM DD, YYYY')
                : 'unknown date'
            }, ${toTitleCase(reservation.channel || 'unknown channel')}`,
          },
          {
            icon: CrossCircleIcon,
            title: 'Cancelled At',
            text:
              reservation.status === 'cancelled' && reservation.cancelledAt
                ? utc(reservation.cancelledAt).format('MMM DD, YYYY')
                : undefined,
          },
          {
            icon: CalendarDatesIcon,
            title: 'Dates',
            text: [reservation.checkIn, reservation.checkOut]
              .filter(hasValue)
              .map((x) => utc(x).format('MMM DD, YYYY'))
              .join(' - '),
          },
          {
            icon: UserIcon,
            title: 'User data',
            text: !!userDataKeys.length && (
              <Anchor onClick={() => setView('edit')}>
                {userDataKeys.length} updated key
                {userDataKeys.length > 1 && 's'}
              </Anchor>
            ),
          },
        ]}
      />

      <PaymentProgress
        currency={reservation.currency}
        financials={reservation.financials.included}
        paymentLines={reservation.paymentLines}
        centPaid={reservation.centPaid}
      />

      <Payments
        currency={reservation.currency}
        rowData={reservation.paymentLines}
        title="Payments"
      />

      {!!reservation.resolutionLines.length && (
        <Payments
          currency={reservation.currency}
          rowData={reservation.resolutionLines}
          title="Payment Fees & Reserves"
        />
      )}

      <Financials
        title="Financials"
        currency={reservation.currency}
        rowData={reservation.financials.included}
        reservationId={reservation.id}
      />

      {!!reservation.financials.exluded.length && (
        <Financials
          title="Financials - Excluded items"
          currency={reservation.currency}
          rowData={reservation.financials.exluded}
          reservationId={reservation.id}
        />
      )}

      <ReservationTimeline data={reservation.timeline} />

      {!!reservation.files.length && <Files rowData={reservation.files} />}
    </>
  );
};

const Financials = ({
  rowData,
  currency,
  title,
  type = 'collapse',
}: {
  rowData: Reservation['financials']['included'];
  currency: string;
  title: string;
  reservationId: string;
  type?: 'tab' | 'collapse';
}) => {
  const [search, setSearch] = useState('');

  const [{ partnerId }] = useTeam();
  const { isSuperAdmin } = useTeamRole();

  const { mutate: upsertSettingMutation, loading: loadingSettingUpsert } =
    useMutation(
      (
        q,
        args: {
          settingId: Maybe<string>;
          type2: string;
          tenantId: string;
          value: 'excluded' | 'invoice';
          isLocalAutomationSetting: boolean;
        }
      ) => {
        if (args.isLocalAutomationSetting && args.settingId) {
          return q.insert_setting_one({
            object: {
              id: args.settingId,
              tenant_id: args.tenantId,
              value: args.value === 'invoice' ? 'invoice' : 'exclude',
              group: 'ximplifi',
              key: 'inclusion',
              target: args.type2,
              lineClassification: args.type2,
              leftType: 'finalytic.lineType',
            },
            on_conflict: {
              constraint: 'setting_pkey',
              update_columns: ['value'],
            },
          })?.id;
        }

        return q.insert_setting_one({
          object: {
            id: args.settingId,
            key: 'accountingType',
            group: 'ximplifi',
            partner: 'ximplifi',
            leftType: 'finalytic.lineType',
            tenant_id: args.tenantId,
            value: args.value,
            target: args.type2,
            lineClassification: args.type2,
          },
          on_conflict: {
            constraint: 'setting_pkey',
            update_columns: ['value'],
          },
        })?.id;
      },
      {
        invalidateQueryKeys: ['reservations'],
      }
    );

  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  const columns = useMemo<
    MRT_ColumnDef<Reservation['financials']['included'][0]>[]
  >(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const data = row.original;

          if (isSuperAdmin)
            return (
              <Group gap={'xs'} wrap="nowrap">
                <Tooltip
                  label={
                    <>
                      {data.type2}
                      {data?.lineItemSetting &&
                        (data?.lineItemSetting?.key === 'inclusion' ? (
                          <>
                            <br />
                            (team setting)
                          </>
                        ) : (
                          <>
                            <br />
                            (partner setting)
                          </>
                        ))}
                    </>
                  }
                  withArrow
                  withinPortal
                >
                  <Center>
                    <InfoIcon size={16} />
                  </Center>
                </Tooltip>
                <Box>
                  <Text size="sm">{data.description}</Text>
                </Box>
              </Group>
            );

          return data.description;
        },
      },
      {
        header: 'amount',
        accessorKey: 'amount',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );

          return amount;
        },
      },
    ],
    [currency, isSuperAdmin]
  );

  const trimmed = search.trim();

  const filtered = useMemo(() => {
    if (!trimmed) return rowData;

    return rowData.filter((row) => {
      return row.description?.toLowerCase().includes(trimmed.toLowerCase());
    });
  }, [trimmed, rowData]);

  if (type === 'tab') {
    return (
      <LazyTable
        data={{
          loading: false,
          error: false,
          rowCount: filtered.length,
          rows: filtered,
        }}
        table={{
          columns,
          // hideTopBar: true,
          hideSettings: true,
          hideHeader: true,
          hidePagination: true,
          emptyRowsFallback: () => (
            <Center>
              <Text size="sm" c="gray">
                No financials available
              </Text>
            </Center>
          ),
        }}
        rowMenu={
          !isSuperAdmin
            ? undefined
            : {
                menuItems: ({ row }) => {
                  const isXimplifiPartner = partnerId === XIMPLIFI_TENANT_ID;

                  if (!row.original.type2 || !isXimplifiPartner) return null;

                  const isExcluded = row.original.isInvoice === 'excluded';

                  const lineItemSetting = row.original.lineItemSetting;

                  const isLocalAutomationSetting =
                    lineItemSetting?.key === 'inclusion';

                  return (
                    <>
                      <HiddenFeatureIndicator permission="super-admin">
                        <EllipsisMenuItem
                          customIcon={<CrossIcon size={16} />}
                          loading={loadingSettingUpsert}
                          onClick={() => {
                            upsertSettingMutation({
                              args: {
                                settingId: lineItemSetting?.id,
                                type2: row.original.type2!,
                                tenantId: partnerId,
                                value: isExcluded ? 'invoice' : 'excluded',
                                isLocalAutomationSetting,
                              },
                            });
                          }}
                        >
                          {isExcluded ? 'Include' : 'Exclude'} item
                        </EllipsisMenuItem>
                      </HiddenFeatureIndicator>
                    </>
                  );
                },
              }
        }
        resetFilter={() => setSearch('')}
      >
        <Filter.Search setValue={setSearch} value={search} />
      </LazyTable>
    );
  }

  return (
    <Box>
      <DrawerCollapsableTable
        title={title}
        rightSection={
          <Text size="sm" fw={500}>
            {formatCurrency(total, currency)}
          </Text>
        }
        rowMenu={
          !isSuperAdmin
            ? undefined
            : {
                menuItems: ({ row }) => {
                  const isXimplifiPartner = partnerId === XIMPLIFI_TENANT_ID;

                  if (!row.original.type2 || !isXimplifiPartner) return null;

                  const isExcluded = row.original.isInvoice === 'excluded';

                  const lineItemSetting = row.original.lineItemSetting;

                  const isLocalAutomationSetting =
                    lineItemSetting?.key === 'inclusion';

                  return (
                    <>
                      <HiddenFeatureIndicator permission="super-admin">
                        <EllipsisMenuItem
                          customIcon={<CrossIcon size={16} />}
                          loading={loadingSettingUpsert}
                          onClick={() => {
                            upsertSettingMutation({
                              args: {
                                settingId: lineItemSetting?.id,
                                type2: row.original.type2!,
                                tenantId: partnerId,
                                value: isExcluded ? 'invoice' : 'excluded',
                                isLocalAutomationSetting,
                              },
                            });
                          }}
                        >
                          {isExcluded ? 'Include' : 'Exclude'} item
                        </EllipsisMenuItem>
                      </HiddenFeatureIndicator>
                    </>
                  );
                },
              }
        }
        rowData={rowData}
        columns={columns}
        emptyRowsFallback={() => (
          <Center>
            <Text size="sm" c="gray">
              No financials available
            </Text>
          </Center>
        )}
      />
    </Box>
  );
};

const Files = ({
  rowData,
}: {
  rowData: Reservation['files'];
}) => {
  const { download } = useFileDownload();

  const [dashboard] = useDashboard();

  return (
    <DrawerCollapsableTable
      title={'Files'}
      rightSection={null}
      rowData={rowData}
      columns={[
        {
          header: 'Name',
          accessorKey: 'filename',
        },
        dashboard !== 'owner'
          ? {
              header: 'Owner',
              accessorKey: 'ownerName',
            }
          : undefined,
        {
          header: 'startDate',
          accessorKey: 'startDate',
        },
      ].filter(hasValue)}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" color="gray">
            No files found
          </Text>
        </Center>
      )}
      onRowClick={{
        handler: (row) => {
          download([row.original.id]);
        },
      }}
    />
  );
};

const PaymentProgress = ({
  financials,
  paymentLines,
  currency,
  centPaid,
}: {
  paymentLines: Reservation['paymentLines'];
  financials: Reservation['financials']['included'];
  centPaid: number;
  currency: string;
}) => {
  const { GL } = useEnabledFeatures();

  const totalFinancials = sum(financials, (x) => x.centTotal || 0);
  const totalPayments = GL
    ? centPaid
    : sum(paymentLines, (x) => x.centTotal || 0);
  const totalPending = totalFinancials - totalPayments;

  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Card
      sx={(theme) => ({
        backgroundColor:
          colorScheme === 'dark' ? undefined : theme.colors.neutral[1],
        display: 'flex',
        flexDirection: 'column',
        gap: rem(14),
      })}
      radius="md"
      mb="sm"
    >
      <Group justify="space-between">
        <Amount
          align="left"
          centTotal={totalPayments}
          currency={currency}
          label="Paid"
        />

        {totalPending ? (
          <Amount
            align="center"
            centTotal={totalPending}
            currency={currency}
            label="Pending"
            isPending
          />
        ) : (
          <Center sx={{ flexDirection: 'column' }}>
            <CheckCircleIcon size={24} color={colors.green[7]} mb={8} />
            <Badge color="green">All Paid</Badge>
          </Center>
        )}

        <Amount
          align="right"
          centTotal={totalFinancials}
          currency={currency}
          label="Expected"
        />
      </Group>

      <Progress
        value={(totalPayments / totalFinancials) * 100}
        color={!totalPending ? 'green' : undefined}
      />
    </Card>
  );
};

const Amount = ({
  align,
  centTotal,
  currency,
  isPending,
  label,
}: {
  align: 'left' | 'center' | 'right';
  centTotal: number;
  currency: string;
  label: string;
  isPending?: boolean;
}) => {
  const amount = formatCurrency(centTotal / 100, currency);
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Box>
      <Text
        ta={align}
        size="sm"
        c={colorScheme === 'dark' ? undefined : isPending ? 'gray' : 'neutral'}
      >
        {label}
      </Text>
      <Text
        ta={align}
        c={
          isPending
            ? colorScheme === 'dark'
              ? colors.gray[6]
              : 'gray'
            : undefined
        }
        fw={500}
        size="lg"
      >
        {amount}
      </Text>
    </Box>
  );
};

const Payments = ({
  rowData,
  currency,
  title,
  type = 'collapse',
}: {
  rowData: Reservation['paymentLines'];
  currency: string;
  title: string;
  type?: 'tab' | 'collapse';
}) => {
  const [search, setSearch] = useState('');

  const setPayment = useQueryParamSet('payment', StringParam);

  const total = sum(rowData, (x) => x.centTotal || 0) / 100;

  type PaymentRow = {
    id: string;
    description: string;
    type: Maybe<Reservation['paymentLines'][0]['type']>;
    centTotal: number;
    isParent: boolean;
    lines: PaymentRow[];
  };

  const groupedRowData = useMemo(
    () =>
      Object.entries(groupBy(rowData, (x) => x.paymentId)).map<PaymentRow>(
        ([paymentId, paymentLines]) => {
          const firstPaymentLine = paymentLines[0];
          const payedAt = firstPaymentLine?.payment?.payedAt || '';

          const description =
            firstPaymentLine?.payment?.description || 'Payment';

          return {
            id: paymentId,
            isParent: true,
            type: firstPaymentLine?.type,
            centTotal: sum(paymentLines, (x) => x.centTotal || 0),
            description: [
              toTitleCase(description),
              payedAt ? `(${utc(payedAt).format('DD MMM YYYY')})` : '',
            ]
              .filter((i) => !!i)
              .join(' '),
            lines: paymentLines.map((paymentLine) => ({
              centTotal: paymentLine.centTotal || 0,
              description: paymentLine.description || '',
              lines: [],
              type: null,
              isParent: false,
              id: paymentLine.id,
            })),
          };
        }
      ),
    [rowData]
  );

  const trimmed = search.trim();

  const filtered = useMemo(() => {
    if (!trimmed) return groupedRowData;

    return groupedRowData.filter((row) => {
      const line = row.description
        ?.toLowerCase()
        .includes(trimmed.toLowerCase());
      const childs = row.lines.some((x) =>
        x.description?.toLowerCase().includes(trimmed.toLowerCase())
      );

      return line || childs;
    });
  }, [trimmed, groupedRowData]);

  const columns = useMemo<MRT_ColumnDef<PaymentRow>[]>(
    () => [
      {
        header: 'Payed On',
        accessorKey: 'description',
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 80,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return formatCurrency((row.original.centTotal || 0) / 100, currency);
        },
      },
    ],
    [currency]
  );

  if (type === 'tab') {
    return (
      <LazyTable
        data={{
          loading: false,
          error: false,
          rowCount: filtered.length,
          rows: filtered,
        }}
        table={{
          columns,
          hideSettings: true,
          hideHeader: true,
          hidePagination: true,
          onRowClick: {
            handler: (row) => {
              if (!row.original.isParent) return;
              setPayment(row.original.id, 'push');
            },
            disabled: (row) => !row.original.isParent,
          },
          emptyRowsFallback: () => (
            <Center>
              <Text size="sm" color="gray">
                No payments available
              </Text>
            </Center>
          ),
        }}
        subRows={{
          getRowCanExpand: (row) => !!row.original.lines.length,
          defaultExpanded: true,
          getSubRows: (row) => row.lines,
        }}
        resetFilter={() => setSearch('')}
      >
        <Filter.Search setValue={setSearch} value={search} />
      </LazyTable>
    );
  }

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={groupedRowData}
      columns={columns}
      subRows={{
        getRowCanExpand: (row) => !!row.original.lines.length,
        defaultExpanded: true,
        getSubRows: (row) => row.lines,
      }}
      onRowClick={{
        handler: (row) => {
          if (!row.original.isParent) return;
          setPayment(row.original.id, 'push');
        },
        disabled: (row) => !row.original.isParent,
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No payments available
          </Text>
        </Center>
      )}
    />
  );
};

const ReservationTimeline = ({ data }: { data: Reservation['timeline'] }) => {
  const { colors } = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();

  return (
    <Box>
      <Collapse
        title={
          <Text component="span" size="sm">
            Timeline
            <Text
              color={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
              ml="xs"
              size="xs"
              component="span"
            >
              {data.length}
            </Text>
          </Text>
        }
        minHeight={30}
        defaultOpened={!!data.length}
      >
        <Timeline data={data} order="desc" />
      </Collapse>
    </Box>
  );
};
