import { gqlV2, useInfiniteQuery } from '@finalytic/data';
import { MRT_SortingState } from '@finalytic/table';
import { getActionMessage } from '@vrplatform/ui-common';
import { useWhereConnections } from './useWhereConnections';

type Params = {
  sorting: MRT_SortingState;
  onlyArchived: boolean;
};

export type ConnectionRow = NonNullable<
  ReturnType<typeof useConnectionTableQuery>['data']
>['pages'][number]['list'][number];

export const getConnection = (
  connection: gqlV2.connection,
  overwrites: {
    pattern: string;
    message: string;
  }[]
) => {
  const extractTypes = ['extract', 'extractLegacy'];

  const lastFetch =
    connection
      .jobPlans({
        where: {
          _or: [
            {
              jobs: {
                kind: { _in: extractTypes },
              },
            },
            {
              type: { _in: extractTypes },
            },
          ],
          isCurrentOnConnection: { _eq: true },
        },
        order_by: [{ createdAt: 'desc_nulls_last' }],
      })
      .map((plan) => ({
        status: plan.status,
        title: getActionMessage(plan.title || '', overwrites),
        createdAt: plan.createdAt,
      }))[0] || null;

  const appVersion = connection?.app?.version || 0;
  const connectionVersion = connection?.version;

  type Status =
    | 'active'
    | 'archived'
    | 'outdated'
    | 'pending'
    | 'started'
    | 'inactive'
    | 'error'
    | 'failed';

  const getStatus = (): Status => {
    if (lastFetch?.status === 'failed') return 'failed';
    if (lastFetch?.status && lastFetch.status !== 'completed') return 'pending';

    const st = (connection.status || 'active') as Status;

    if (connectionVersion === undefined || connectionVersion === null)
      return st;

    if (connectionVersion < appVersion) return 'outdated';

    return st;
  };

  return {
    id: connection?.id,
    status: getStatus(),
    connectionStatus: connection?.status,
    name: connection?.name,
    createdAt: connection?.createdAt,
    uniqueRef: connection?.uniqueRef,
    lastFetch,
    tenant: {
      id: connection?.tenant.id,
      name: connection?.tenant.name,
      logo: connection?.tenant.logo!,
      colorPrimary: connection?.tenant.colorPrimary!,
    },
    app: {
      id: connection?.app.id,
      iconRound: connection?.app.iconRound!,
      name: connection?.app.name!,
      category: connection?.app.category!,
    },
  };
};

export const useConnectionTableQuery = ({ sorting, onlyArchived }: Params) => {
  const where = useWhereConnections({ onlyArchived });

  return useInfiniteQuery(
    (q, { where, sorting }, { limit, offset }) => {
      const aggregate =
        q.connectionAggregate({ where }).aggregate?.count() || 0;

      const order_by = sorting.map((sort) => ({
        [sort.id]: sort.desc ? 'desc_nulls_last' : 'asc_nulls_last',
      }));

      const overwrites = q
        .issueMessageOverwrites({
          order_by: [{ pattern: 'asc_nulls_last' }],
        })
        .map((o) => ({
          pattern: o.pattern || '',
          message: o.message || '',
        }));

      const list = q
        .connection({
          where,
          limit,
          offset,
          order_by,
        })
        .map((c) => getConnection(c, overwrites));

      return {
        list,
        aggregate,
      };
    },
    {
      queryKey: 'connections',
      variables: { where, sorting },
    }
  );
};
