import {
  ReservationsService,
  TrpcApiError,
  captureSentryError,
  useInvalidateQueries,
  useReservationsServiceUpdateReservation,
} from '@finalytic/data';
import {
  DeleteModal,
  showLoadingNotification,
  showWarnNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import { useId } from 'react';

type Props = {
  reservationLine: {
    id: string;
    reservationId: string;
  } | null;
  closeModal: () => void;
};

export const ReservationLineDeleteModal = ({
  reservationLine,
  closeModal,
}: Props) => {
  const opened = !!reservationLine;

  const invalidate = useInvalidateQueries(['reservations']);
  const notifyId = useId();
  const { mutateAsync, isLoading: loading } =
    useReservationsServiceUpdateReservation({
      onSuccess: () => {
        invalidate();
      },
    });

  const submit = async () => {
    if (!reservationLine) {
      captureSentryError('Missing line');
      return showWarnNotification({
        title: 'Missing line',
        message: 'Please reach out to support if the issue persists.',
      });
    }

    try {
      showLoadingNotification({
        id: notifyId,
      });

      const existing_lines = (
        await ReservationsService.getReservation({
          id: reservationLine.reservationId,
        })
      ).lines;

      type ReservationLine = NonNullable<
        Parameters<typeof mutateAsync>[0]['requestBody']['lines']
      >[number];

      const lines = existing_lines
        .filter((line) => line.id !== reservationLine.id)
        .map<ReservationLine>((line) => ({
          amount: line.amount,
          connectionId: line.connectionId ?? undefined,
          description: line.description ?? undefined,
          type: line.type,
        }));

      await mutateAsync({
        id: reservationLine.reservationId,
        requestBody: {
          lines,
        },
      });

      closeModal();
      updateSuccessNotification({
        id: notifyId,
        title: 'Success!',
        message: 'Financial was deleted successfully.',
      });
    } catch (error: any) {
      const err = error as TrpcApiError;

      const message =
        err?.body?.message ||
        'We failed to update the reservation. Please try again later and if the problem persists, contact support.';

      updateErrorNotification({
        id: notifyId,
        title: 'Failed to update reservation',
        message,
      });
    }
  };

  return (
    <DeleteModal
      opened={opened}
      onClose={closeModal}
      onSubmit={async () => await submit()}
      loading={loading}
      title={'Are you sure you want to delete the adjustment?'}
      subtitle="Please refresh the journal entries of this reservation after deletion."
    />
  );
};
