import { utc } from '@finalytic/utils';

export const getStatementYearlySummaryHref = ({
  date,
  ownerId,
  listingId,
}: {
  listingId?: string;
  ownerId: string;
  date?: string;
}) => {
  const dateFormat = 'YYYY-MM-DD';

  const url = new URL(window.location.origin);
  url.searchParams.set('summary', 'groupByMonth');
  url.searchParams.set('startAt', utc(date).startOf('year').format(dateFormat));
  url.searchParams.set('endAt', utc(date).endOf('year').format(dateFormat));
  if (date) url.searchParams.set('date', utc(date).format(dateFormat));
  if (listingId) url.searchParams.set('summaryListing', listingId);
  if (ownerId) url.searchParams.set('summaryOwner', ownerId);

  console.log(url.pathname + url.search);

  return `/statements/summary${url.search}`;
};
